import FeaturePage from "views/components/feature-page";
import img from "app/assets/png/virtual_bank.png";
import { Helmet } from "react-helmet";

const features = [
  "Relocating abroad? Working remotely, or collecting tips from foreign fans? Get a dollar, pound, or euro bank account on Changera and collect payments globally.",
  "Convert and withdraw funds straight to local currency at attractive rates.",
  "Fund African wallets such as naira wallet on Changera with a virtual naira account."
];

const VirtualBank = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Receive money in Dollars, Pounds, Euros from anywhere in the world,  directly into your virtual bank account on Changera app"
        ></meta>
        <title>
          Open a Domiciliary account for foreign payments on Changera
        </title>
        <link rel="canonical" href="https://changera.co/virtual-bank" />
      </Helmet>
      <FeaturePage
        title="Open a local or domiciliary account without visiting a bank and collect  local and foreign payments in USD, GBP, and EUR  easily."
        features={features}
        img={img}
        actionLine="Explore Changera Now"
        catchPhrase="VIRTUAL BANK ACCOUNT"
      />
    </div>
  );
};

export default VirtualBank;
