import { useRoutes } from "react-router-dom";
import routes from "app/routes";
import { Suspense } from "react";
import Loading from "views/components/loading";

const App = (): JSX.Element => {
  const routesHere = useRoutes(routes);

  return (
    <div className="max-w-[2000px] mx-auto">
      <Suspense fallback={<Loading />}>{routesHere}</Suspense>
    </div>
  );
};

export default App;
