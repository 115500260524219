import FeaturePage from "views/components/feature-page";
import dollarCard from "app/assets/png/card.png";
import { Helmet } from "react-helmet";

const features = [
  "Move light. Access your money anywhere with a Changera EasyDollar Card.",
  "Works for all MasterCard or VISA online transactions globally.",
  "Fund with local currency, get over $10k in monthly limits. Withdraw from cards anytime.",
  "Pay suppliers, Netflix, online ads, international tuition, flights, Airbnb, or food all over the world."
];

const Cards = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Need a Virtual dollar card for fast online payments and subscriptions?. Get Changera virtual card, Africa’s best Virtual card, with no limits. Download now for free"
        ></meta>
        <title>The Best Virtual cards are available on Changera</title>
        <link rel="canonical" href="https://changera.co/virtual-cards" />
      </Helmet>
      <FeaturePage
        title="Experience borderless money with a global debit card"
        features={features}
        img={dollarCard}
        actionLine="Request an EasyDollar Card"
        catchPhrase="EASY DOLLAR DEBIT CARD"
      />
    </div>
  );
};

export default Cards;
