import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import changeraLogo from "app/assets/svg/logo1.svg";
import changeraLogoWhite from "app/assets/svg/whiteLogo.svg";
import { newRouteList } from "app/routes/route-list";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { useMemo, useRef } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import GetStartedLink from "views/components/get-started-link";

const Header = (): JSX.Element => {
  //
  const location = useLocation();
  const bgColor = useMemo(() => {
    if (location.pathname === "/") {
      return "white";
    } else if (location.pathname === "/business") {
      return "gray.100";
    } else {
      return "gray.100";
    }
  }, [location]);

  const whiteColor = useMemo(() => {
    if (location.pathname === "/") {
      return true;
    } else {
      return false;
    }
  }, [location]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  //
  const navigate = useNavigate();

  return (
    <Stack
      fontFamily={"'Urbanist Variable'"}
      as={"header"}
      borderRadius="full"
      direction="row"
      mx={["6", "6", "6", "6rem"]}
      px={["1", "1", "1", "1.5rem"]}
      bgColor={"#0A0F1F"}
      h="20"
      alignItems="center"
      justifyContent="space-between"
      mb={"-90px"}
      zIndex="999"
      position={["relative", "relative", "relative", "sticky"]}
      top={[0, 0, 0, 2]}
    >
      <Link to="">
        <Image src={whiteColor ? changeraLogoWhite : changeraLogo} h="8" />
      </Link>
      <Stack
        direction="row"
        spacing={"16"}
        fontFamily="'Urbanist Variable'"
        display={["none", "none", "none", "flex"]}
      >
        {newRouteList.map((el, idx) =>
          el.children ? (
            <Menu key={idx}>
              <MenuButton
                fontFamily={"'Urbanist Variable'"}
                as={Box}
                textTransform="capitalize"
                color={whiteColor ? "#C6CCD9" : "gray.600"}
                w={"max-content"}
                fontSize="md"
                _hover={{
                  fontWeight: "600",
                  color: whiteColor ? "#fff" : "primary.500"
                }}
                cursor="pointer"
              >
                <Flex alignItems={"center"} justifyContent="center" gap={"1"}>
                  <Text>{el.title}</Text>
                  <Icon as={AiFillCaretDown} />
                </Flex>
              </MenuButton>
              <MenuList
                bg="orange.100"
                boxShadow="2xl"
                borderColor="orange.100"
                zIndex={"999 !important"}
              >
                {el.children.map((el_, idx) => (
                  <MenuItem
                    key={idx}
                    _hover={{ bg: (theme) => theme.colors.orange[500] }}
                    pr="14"
                    onClick={() => navigate(el_.to)}
                    fontFamily={"'Urbanist Variable'"}
                  >
                    {el_.title}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : (
            <ChakraLink
              fontFamily={"'Urbanist Variable'"}
              as={Link}
              to={el.to}
              key={idx}
              textTransform="capitalize"
              fontWeight={location.pathname === `/${el.to}` ? "700" : "500"}
              color={
                whiteColor
                  ? location.pathname === `/${el.to}`
                    ? "#fff"
                    : "#C6CCD9"
                  : location.pathname === `/${el.to}`
                  ? "gray.900"
                  : "gray.600"
              }
              w={"max-content"}
              fontSize="md"
              _hover={{
                fontWeight: "700",
                color: whiteColor ? "#fff" : "primary.500"
              }}
            >
              {el.title}
            </ChakraLink>
          )
        )}
      </Stack>
      <GetStartedLink>
        <Button
          bgColor={"#40BB6B"}
          fontWeight="semibold"
          borderRadius={"full"}
          px="6"
          color={"#24242A"}
          py="6"
          display={["none", "none", "none", "flex"]}
          _hover={{ boxShadow: "0px 4px 22px rgba(8, 12, 26, 0.25)" }}
        >
          Download Now
        </Button>
      </GetStartedLink>
      <Icon
        as={IoMenuOutline}
        color="#fff"
        boxSize="8"
        display={["block", "block", "block", "none"]}
        onClick={onOpen}
        ref={btnRef}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg={bgColor}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Link to="">
              <Image src={changeraLogo} h="6" />
            </Link>
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing={"8"} fontFamily="'Urbanist Variable'">
              {newRouteList.map((el, idx) =>
                el.children ? (
                  <Menu key={idx}>
                    <MenuButton
                      as={Box}
                      textTransform="capitalize"
                      color="gray.600"
                      w={"max-content"}
                      fontSize="md"
                      _hover={{
                        fontWeight: "600",
                        color: "primary.500"
                      }}
                      cursor="pointer"
                    >
                      <Flex
                        alignItems={"center"}
                        justifyContent="center"
                        gap={"1"}
                      >
                        <Text>{el.title}</Text>
                        <Icon as={AiFillCaretDown} />
                      </Flex>
                    </MenuButton>
                    <MenuList
                      bg="orange.100"
                      boxShadow="2xl"
                      borderColor="orange.100"
                      zIndex={"999 !important"}
                    >
                      {el.children.map((el_, idx) => (
                        <ChakraLink
                          w="full"
                          h="full"
                          as={Link}
                          to={el_.to}
                          _hover={{ textDecor: "none" }}
                          onClick={onClose}
                          key={idx}
                        >
                          <MenuItem
                            _hover={{ bg: (theme) => theme.colors.orange[500] }}
                            pr="14"
                          >
                            {el_.title}
                          </MenuItem>
                        </ChakraLink>
                      ))}
                    </MenuList>
                  </Menu>
                ) : (
                  <ChakraLink
                    as={Link}
                    to={el.to}
                    key={idx}
                    textTransform="capitalize"
                    color="gray.600"
                    w={"max-content"}
                    fontSize="md"
                    _hover={{
                      fontWeight: "600",
                      color: "primary.500"
                    }}
                    onClick={onClose}
                  >
                    {el.title}
                  </ChakraLink>
                )
              )}
              <GetStartedLink>
                <Button
                  color={"#24242A"}
                  bgColor={"#40BB6B"}
                  borderRadius="full"
                  colorScheme="primary"
                  fontWeight="semibold"
                  px="6"
                  py="6"
                  w="fit-content"
                >
                  Download Now
                </Button>
              </GetStartedLink>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  );
};

export default Header;
