import { Link, LinkProps } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export default function GetStartedLink({ children, ...rest }: LinkProps) {
  const location = useLocation();

  return (
    <Link
      href={
        location.pathname === `/business`
          ? "https://business.changera.co"
          : "https://linktr.ee/changeraapps"
      }
      target={"_blank"}
      rel="noreferrer"
      {...rest}
      textDecoration="none !important"
    >
      {children}
    </Link>
  );
}
