import { Box, Button, Image, List, ListIcon, ListItem } from "@chakra-ui/react";

import underline from "app/assets/svg/underline.svg";
import changeraTape from "app/assets/svg/changeraTape.svg";

import { IoIosCheckmark } from "react-icons/io";
import GetStartedLink from "views/components/get-started-link";
const pricingList = [
  {
    title: "Freemium",
    price: "$0",
    catchPhrase: "Best for all users",
    features: [
      "Gain instant access to 3 wallet currencies",
      "Create up to 3 free virtual dollar cards",
      "Virtual card limits up to $10,000 per month",
      "Purchase airtime and data",
      "Transfer money within 24 - 36 hours ",
      "Cash-out funds with MoneyGram",
      "Access tickets to events globally"
    ],
    colors: {
      chakra: "gray.100",
      hex: "#536ECD"
    }
  },
  {
    title: "Freelancer",
    price: "$50",
    catchPhrase: "Best for freelancers",
    features: [
      "Gain instant access to 7 wallet currencies",
      "Access to USD, GBP and EUR virtual accounts",
      "Virtual card limit up to $50,000 per month",
      "Transfer money within 24 - 36 hours",
      "Exclusive access to massive cashbacks",
      "Access to exclusive events and discounted ticket prices to events",
      "Priority support"
    ],
    colors: {
      chakra: "success.600",
      hex: "#126730"
    }
  },
  {
    title: "Premium",
    price: "$100",
    catchPhrase: "Best for the hard players",
    features: [
      "Access to USD, GBP and EUR virtual accounts",
      "Virtual card limit up to $100,000 per month",
      "Transfer money within 24 - 36 hours",
      "Transfer processing within 1 - 3 hours (subject to destination)",
      "Exclusive access to massive cashbacks",
      "Access to exclusive events and discounted ticket prices to events",
      "Priority support"
    ],
    colors: {
      chakra: "gray.100",
      hex: "#DF9949"
    }
  }
];

export const Pricing = () => {
  return (
    <div>
      <Box
        px={["6", "6", "6", "8rem"]}
        py={["10", "10", "16", "20"]}
        className="bg-white"
        maxW={"1600px"}
        mx="auto"
      >
        <h3 className="text-center font-extrabold text-5xl md:text-6xl text-[#212327] w-fit relative mx-auto font-bricolage">
          Ready to get <span className="text-[#126730]">started? </span>
          <Image
            src={underline}
            position={["absolute"]}
            // zIndex="-1"
            margin="auto"
            right={20}
            bottom={-8}
            className="w-[50%] md:w-auto"
          />
        </h3>
        <div className="flex flex-col md:flex-row gap-5 mt-12 md:mt-20">
          {pricingList.map((el, idx) => (
            <PricingCard key={idx} data={el} />
          ))}
        </div>
      </Box>

      <Image src={changeraTape} className="w-full my-6" />
    </div>
  );
};

const PricingCard = ({
  data
}: {
  data: {
    title: string;
    price: string;
    catchPhrase: string;
    features: string[];
    colors: {
      chakra: string;
      hex: string;
    };
  };
}) => {
  return (
    <div className="bg-[#F2F3F4] rounded-[38px] p-6 w-full">
      <p
        className="text-center uppercase text-lg md:text-xl font-semibold"
        style={{ color: data.colors.hex }}
      >
        {data.title}
      </p>
      <p className="text-center text-[#667085] text-lg md:text-xl font-medium mt-2">
        {data.catchPhrase}
      </p>
      <p className="text-[#212327] text-center font-semibold text-6xl md:text-[70px] mt-5 font-bricolage">
        {data.price}
        <span className="text-[#667085] text-lg">/year</span>{" "}
      </p>

      <div className="flex justify-center">
        <GetStartedLink>
          <Button
            bgColor={"#141D3E"}
            fontWeight="semibold"
            borderRadius={"full"}
            color={"#FCFDFD"}
            textTransform="capitalize"
            px="8"
            py="6"
            className="my-6 md:my-8"
            _hover={{ boxShadow: "0px 4px 22px rgba(8, 12, 26, 0.25)" }}
          >
            Get {data.title}
          </Button>
        </GetStartedLink>
      </div>

      <List spacing={3} mt={1}>
        {data.features.map((el, idx) => (
          <ListItem display={"inline-flex"} key={idx} className="py-1">
            <ListIcon
              as={IoIosCheckmark}
              color={"#444B59"}
              mt="1"
              // size={"24px"}
              width={"20px"}
              height={"20px"}
            />
            <p className="text-[#444B59] text-lg">{el}</p>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
