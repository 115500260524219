import {
  Box,
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";

const PrivacyPolicies = () => (
  <Box
    px={["6", "6", "6", "7.5rem"]}
    py={["16", "16", "16", "24"]}
    bg="orange.50"
  >
    <Heading textAlign={"center"} pb="10">
      Bitmama Privacy Policy{" "}
    </Heading>
    <Stack spacing={"10"} fontSize="xl" px={["4", "4", "16", "24", "44"]}>
      <Text>
        We are Bitmama Inc, a Delaware corporation at 2093 PHILADELPHIA PIKE
        7093 CLAYMONT,DE19703 USA
      </Text>
      <Text>
        Bitmama Inc. and its affiliates (collectively, “Bitmama) do not take
        your trusting us with your information for granted. The privacy of our
        Users matters to us hence we are committed to protecting it. Therefore,
        this Privacy Policy contains an overview of the collection, use, and
        disclosure of your personal data across our website, APIs, applications,
        email notifications, and tools.
      </Text>
      <Text>
        Bitmama takes the privacy of your information very seriously. Please
        read this Policy carefully as it applies when using the Site and any of
        our services. As a Customer, you accept this Policy when you sign up
        for, access, or use our products, services, content, features,
        technologies or functions offered on our website and all related sites,
        applications, and services.
      </Text>
      <Text>
        This Policy regulates how personal information will be used within the
        Company. To preserve the confidentiality of your data, we maintain the
        controls in place for privacy.
      </Text>
      <Text fontWeight={"bold"}>
        This Policy is intended to govern the use of the Site by users. Users
        reserve the right to exercise their data protection rights as listed
        under the Customer Rights.
      </Text>
      <Stack>
        <Heading as={"h4"} fontSize="xl">
          Overview
        </Heading>
        <Text>
          This Privacy Policy describes your privacy rights regarding how and
          when we collect, use, store, share and protect your information across
          our website, payment platforms (“Platforms”), APIs, Software
          Applications, (“Apps”), email notifications, and tools regardless of
          how you use or access them.
        </Text>
      </Stack>
      <Stack>
        <Heading as={"h4"} fontSize="xl">
          Who are we
        </Heading>
        <Text>
          Bitmama Inc. ("Bitmama", "us", "we", "our") is responsible for the
          collection, use, and disclosure of your personal information. If you
          have any questions about how we protect or use your data, please email
          us at support@bitmama.io with the subject line Privacy
        </Text>
      </Stack>
      <Stack spacing={"6"}>
        <Heading as={"h4"} fontSize="2xl">
          Definition
        </Heading>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Account:
          </Text>{" "}
          means a Changera account a product by Bitmama Inc
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Cookies:
          </Text>{" "}
          A cookie is a small data file that is transferred to your computer or
          mobile device. It enables us to remember your account log-in
          information, IP addresses, web traffic number of times you visit,
          browser type and version, device details, date and time of visits.
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Bitmama:
          </Text>{" "}
          Bitmama means “Bitmama Inc” and subsidiaries or affiliates.
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Personal Information:
          </Text>{" "}
          Any information that can be used to identify a living person including
          email address, company name, password, payment card, financial
          information such as bank account number, etc., Government-issued
          Identity card, and/or taxpayer-identification it may also include
          anonymous information that is linked to you, for example, your
          internet protocol (IP), log-in information, address, location, device
          or transaction data.
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Sites:
          </Text>{" "}
          means any platform including but not limited to mobile applications,
          websites, and social media platforms.
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            User:
          </Text>{" "}
          means an individual who uses the Services or accesses the Sites and
          has agreed to use the end services of Bitmama.
        </Text>
        <Text>
          Special Categories of Personal Information means details about your
          race or ethnicity, religious or philosophical beliefs, sex life,
          sexual orientation, political opinions, trade union membership,
          information about your health, and genetic and biometric data.
        </Text>
      </Stack>
      <Stack>
        <Heading as={"h4"} fontSize="xl">
          Objective
        </Heading>
        <Text>
          This policy statement explains to our customers whose personal data we
          hold with a description of the types of Personal Information we
          collect, the purposes for which we collect that Personal Information,
          the other parties with whom we may share it, and the measures we take
          to protect the security of the data.
        </Text>
      </Stack>
      <Stack spacing={"6"}>
        <Heading as={"h4"} fontSize="xl">
          Personal Information We May Collect About You
        </Heading>
        <Text>
          We may collect, use, process, store, or transfer personal information
          such as:
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Identity Data:
          </Text>{" "}
          Information such as your full name(s), your government-issued identity
          number, and your date of birth. This data is to enable us to verify
          your identity in order to offer our services to you;
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Contact Data:
          </Text>{" "}
          This is data that is needed to reach out to you, such as your contact
          address, email address, telephone number, details of the device you
          use, and billing details
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Identification documents:
          </Text>{" "}
          :(such as your passport or any Government-issued identity card), a
          photograph (if applicable), and any other registration information you
          may provide to prove you are eligible to use our services;
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Log/Technical information:
          </Text>{" "}
          When you access Bitmama services, our servers automatically record
          information that your browser sends whenever you visit a website,
          links you have clicked on, length of visit on certain pages, unique
          device identifier, log-in information, location, and other device
          details.
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Financial Data:
          </Text>{" "}
          Information, such as personal account number, the merchant’s name and
          location, the date and the total amount of transaction, and other
          information provided by financial institutions or merchants when we
          act on their behalf;
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Transactional Data:
          </Text>{" "}
          These are information relating to payment when you as a merchant
          (using one or more of our payment processing services) or as a
          customer, are using our products or services;
        </Text>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            Marketing and Communications Data:
          </Text>{" "}
          This includes both a record of your decision to subscribe or to
          withdraw from receiving marketing materials from us or from our third
          parties.
        </Text>
        <Text>
          Records of your discussions with us, if we contact you, and if you
          contact us.
        </Text>
        <Text>
          We may also collect, store, use, and transfer non-personal information
          or anonymized data such as statistical or demographic data.
        </Text>
        <Text>
          This Privacy Policy applies to Bitmama services only. We do not
          exercise control over the sites displayed or linked from within our
          various services. These other sites may place their own cookies,
          plug-ins, or other files on your computer, collect data or solicit
          personal information from you. Bitmama does not control these
          third-party websites and we are not responsible for their privacy
          statements. Please consult such third parties’ privacy statements.
        </Text>
      </Stack>
      <Stack>
        <Heading as={"h4"} fontSize="2xl">
          Data we may collect about you
        </Heading>
        <Text>
          We will collect, use, process, store, or transfer personal information
          such as:
        </Text>
        <UnorderedList>
          <ListItem>Information is given to us by you.</ListItem>
        </UnorderedList>
        <OrderedList spacing={"4"} pl="6">
          <ListItem>
            Information, including personal information, when signing up to use
            our Services about yourself, for example, your name and email
            address, given to us by you. This also includes information provided
            through continued use of our Services, taking part in discussion
            boards or other social media functions on our Website or App,
            entering a competition, promotion, or survey, and when you report a
            problem with our Services. The information you give us may include
            your name, address, e-mail, phone number, financial information
            (including debit card, credit card, or bank account information),
            payment reason, geographical location, social security number,
            personal description, and photograph.
          </ListItem>
          <ListItem>
            If you perform high-value or high-volume transactions either by
            sending or receiving, we may also need additional commercial and/or
            identification information from you, as needed to comply with our
            anti-money laundering obligations under applicable law.
          </ListItem>
          <ListItem>
            In providing the personal data of any individual (other than
            yourself) that for example may receive payments from us as part of
            your use of our Services, you promise that you have obtained consent
            from such individual to disclose his/her data to us, as well his/her
            consent to our collection, use, and disclosure of such personal
            data, for the purposes set out in this Privacy Policy.
          </ListItem>
        </OrderedList>
      </Stack>
      <Stack spacing={"2"}>
        <Heading as={"h4"} fontSize="2xl">
          Information we collect about you.
        </Heading>
        <Text>
          Using our Services, we may collect the following information, not
          limited to but may be or include personal information:
        </Text>
        <UnorderedList spacing={"4"}>
          <ListItem>
            Details of the transactions you perform using our Services,
            including geographic location from which the transaction originates;
          </ListItem>
          <ListItem>
            Technical information, including the internet protocol (IP) address
            used to connect your computer to the Internet, your login
            information, browser type, and version, time zone setting, browser
            plug-in types and versions, operating system and platform;
          </ListItem>
          <ListItem>
            Information about your visit, including the full Uniform Resource
            Locators (URL) clickstream to, through, and from our Website or App
            (including date and time); products you viewed or searched for; page
            response times, download errors, length of visits to certain pages,
            page interaction information (such as scrolling, clicks, and
            mouse-overs), and methods used to browse away from the page and any
            phone number used to call our Customer Support number.
          </ListItem>
          <ListItem>
            Financial Data: Information, such as personal account number, the
            merchant’s name and location, the date and the total amount of
            transaction, and other information provided by financial
            institutions or merchants when we act on their behalf;
          </ListItem>
          <ListItem>
            Transactional Data: These are information relating to payment when
            you as a merchant (using one or more of our payment processing
            services) or as a customer, are using our products or services;
          </ListItem>
        </UnorderedList>
        <Text py="4" fontSize={"2xl"}>
          Information we receive from other sources. We may receive information
          about you if you use any of the other websites or services we provide.
          We are also working closely with third parties and may receive
          information about you from them. For example:
        </Text>
        <UnorderedList spacing={"4"}>
          <ListItem>
            the banks you use to transfer money to us will provide us with your
            basic personal information, such as your name and address, as well
            as your financial information such as your bank account details;
          </ListItem>
          <ListItem>
            business partners may provide us with your name and address, as well
            as financial information, such as card payment information;
          </ListItem>
          <ListItem>
            Information from social media networks. If you log in to our
            Services using your social media account (for example, Facebook or
            Google) we will receive relevant information that is necessary to
            enable our Services and authenticate you.
          </ListItem>
          <ListItem>
            Children’s information Our products and services are directed at
            adults 18 years and over and not intended for children. We do not
            knowingly collect information from this age group. Part of the
            verification process prevents Wise from collecting such data. If any
            information is collected from a child without verification of
            parental consent, it will be deleted.
          </ListItem>
        </UnorderedList>
        <Text py="4" fontSize={"2xl"}>
          Information we receive from other sources. We may receive information
          about you if you use any of the other websites or services we provide.
          We are also working closely with third parties and may receive
          information about you from them. For example:
        </Text>
        <Text py="4" fontSize={"2xl"}>
          Information we receive from other sources. We may receive information
          about you if you use any of the other websites or services we provide.
          We are also working closely with third parties and may receive
          information about you from them. For example:
        </Text>
      </Stack>
      <Stack spacing={"4"}>
        <Heading as={"h4"} fontSize="2xl">
          How we get your Personal Information and why we have it
        </Heading>
        <Text>
          The Personal Information we have about you is directly made available
          to us when you:
        </Text>
        <Stack>
          <Text>Sign up for a Changera Account a product of Bitmama;</Text>
          <UnorderedList pl="6">
            <ListItem>Use any of our services;</ListItem>
            <ListItem>Contact our customer support team;</ListItem>
            <ListItem>Fill our online forms;</ListItem>
            <ListItem>Contact us;</ListItem>
            <ListItem>
              The lawful basis we rely on for processing your Personal
              Information are:
            </ListItem>
          </UnorderedList>
        </Stack>
        <Text>
          Your Consent: Where you agree to us collecting your Personal
          Information by using our Services.
        </Text>
        <Text>
          We have a contractual obligation: Without your Personal Information,
          we cannot provide our Services to you.
        </Text>
        <Text>
          We have a legal obligation: To ensure we are fully compliant with all
          applicable Financial legislations such as Anti-Money Laundering and
          Counter-Terrorist Financing Laws, we must collect and store your
          Personal Information. We protect against fraud by checking your
          identity with your Personal Information.
        </Text>
      </Stack>
      <Stack spacing={"4"}>
        <Heading as={"h4"} fontSize="2xl">
          How We May Use Your Personal Information
        </Heading>
        <Text>We may use your Personal Information we collect to:</Text>
        <UnorderedList spacing={"2"} pl="5">
          <ListItem>
            Create and manage any accounts you may have with us, verify your
            identity, provide our services, and respond to your inquiries;
          </ListItem>
          <ListItem>
            Process your payment transactions (including authorization,
            clearing, chargebacks, and other related dispute resolution
            activities);
          </ListItem>
          <ListItem>
            Protect against and prevent fraud, unauthorized transactions,
            claims, and other liabilities;
          </ListItem>
          <ListItem>
            Provide, administer and communicate with you about products,
            services, offers, programs, and promotions of Bitmama, financial
            institutions, merchants, and partners;
          </ListItem>
          <ListItem>
            Evaluate your interest in employment and contact you regarding
            possible employment with Bitmama;
          </ListItem>
          <ListItem>
            Evaluate and improve our business, including developing new products
            and services;
          </ListItem>
          <ListItem>
            To target advertisements, newsletter, and service updates;
          </ListItem>
          <ListItem>
            As necessary to establish, exercise, and defend legal rights;
          </ListItem>
          <ListItem>
            As may be required by applicable laws and regulations, including for
            compliance with Know Your Customers and risk assessment, Anti-Money
            Laundering, anti-corruption and sanctions screening requirements, or
            as requested by any judicial process, law enforcement or
            governmental agency having or claiming jurisdiction over Bitmama or
            Bitmama’s affiliates;
          </ListItem>
          <ListItem>
            To use data analytics to improve our Website, products or services,
            and user experiences.
          </ListItem>
          <ListItem>
            For other purposes for which we provide specific notice at the time
            of collection.
          </ListItem>
        </UnorderedList>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="xl">
          Data Security and Retention
        </Heading>
        <Text>
          The security of your Personal Information is important to Bitmama. We
          maintain appropriate Internal, technical, and physical controls to
          ensure that your data are kept safe at Bitmama. Other security
          measures include but are not limited to, secure servers, firewalls,
          data encryption, and granting access only to designated employees. On
          your part strive to ensure your passwords to your Accounts are safe.
        </Text>
        <Text>
          The data that we collect from you may be transferred to and stored at,
          a destination outside the United States. It may also be used by staff
          operating outside the US who work for us or for one of our suppliers.
          By submitting your personal information, you agree to this transfer,
          storing, or processing. We will take all steps reasonably necessary to
          ensure that your data is treated securely and in accordance with this
          Privacy Policy.
        </Text>
        <Text>
          We will only retain personal information on our servers for as long as
          is reasonably necessary as long as we are providing Services to you.
          When you close your Account, your information is stored on our servers
          to the extent necessary to comply with regulatory obligations and for
          the purpose of fraud monitoring, detection, and prevention. Where we
          retain your Personal Data, we do so in compliance with limitation
          periods under the applicable law.
        </Text>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="xl">
          Electronic Fund Transfers (EFTs) and Account Balances.
        </Heading>
        <Text>
          Bitmama Inc partners with financial services software company Sila
          Inc. (Sila) and banking services provider Evolve Bank & Trust
          (Evolve), member FDIC, to offer you electronic fund transfers (EFTs).
          When you create a Changera account, a product of Bitmama, link a bank
          account, or initiate an EFT, you are authorizing us to share your
          identity and banking information with Sila and Evolve to support your
          account. You are also agreeing to the terms of Sila’s privacy policy,
          https://silamoney.com/privacy-policy/, Evolve’s a privacy policy,
          https://www.getevolved.com/privacy-policy/, and Evolve’s
          communications consent and disclosure,
          https://silamoney.com/evolve-electronic-communications-consent-and-disclosure/
          (the “Partner Terms”). It is your responsibility to make sure the data
          you provide us is accurate and complete, which is necessary for our
          partners to process EFTs on your behalf. The Partner Terms may be
          modified from time to time, and the governing versions are
          incorporated by reference into this Privacy Policy. Any term not
          defined in this section but defined in the Partner Terms assumes the
          meaning as defined in the Partner Terms. IT IS YOUR RESPONSIBILITY TO
          READ AND UNDERSTAND THE PARTNER TERMS BECAUSE THEY CONTAIN TERMS AND
          CONDITIONS CONCERNING YOUR CHANGERA ACCOUNT A PRODUCT BY BITMAMA,
          INCLUDING BUT NOT LIMITED TO THE USE OF YOUR PERSONAL INFORMATION.
        </Text>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="2xl">
          California Related Rights
        </Heading>
        <Text>
          <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
            California Privacy Rights.
          </Text>{" "}
          If you are a resident of the State of California, under the California
          Consumer Privacy Act (CCPA), you have the right to request information
          on how to exercise your disclosure choice options from companies
          conducting business in California. Specifically:{" "}
        </Text>
        <UnorderedList pl="5" spacing={"2"}>
          <ListItem>
            <Text>
              <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
                Exercising the Right to Know.
              </Text>{" "}
              You may request, up to twice in a 12-month period, the following
              about the personal information collected on you in the past 12
              months: o the categories and specific pieces of personal
              information we have collected; o the categories of sources from
              which we collected the personal information; o the business or
              commercial purpose for which we collected it; the categories of
              third parties with whom we shared the personal information; and
              the categories of personal information about you that we disclosed
              for a business purpose.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
                Exercising the right to delete.{" "}
              </Text>{" "}
              You may request that we delete the personal information we have
              collected from you, subject to certain limitations under
              applicable law.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
                Exercising the right to opt-out from a sale
              </Text>{" "}
              You may request to opt out of any “sale” of your personal
              information that may take place.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
                Non-discrimination.
              </Text>{" "}
              The CCPA provides that you may not be discriminated against for
              exercising these rights
            </Text>
          </ListItem>
        </UnorderedList>
        <Text>
          To submit a request to exercise any of the rights described above, you
          may contact Bitmama Inc either via email to{" "}
          <a className="text-green-500" href="mailto:support@bitmama.io">
            {" "}
            support@bitmama.io
          </a>
        </Text>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="2xl">
          Disclosing Your Personal Information to third parties
        </Heading>
        <Text>
          We may disclose or share your Personal Information with third parties
          which include our affiliates, employees, officers, service providers,
          agents,suppliers, subcontractors as may be reasonably necessary for
          the purposes set out in this policy.
        </Text>
        <Text>
          We have your consent. We require opt-in consent for the sharing of any
          personal information; We share Personal Information with third parties
          directly authorized by you to receive Personal Information, such as
          when you authorize a third party application provider to access your
          account. The use of your Personal Information by an authorized third
          party is subject to the third party's privacy policy and Bitmama shall
          bear no liability for any breach which may arise from such
          authorization by you.
        </Text>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="2xl">
          Cookies{" "}
        </Heading>
        <Text>
          We use cookies to distinguish you from other users and to customize
          and improve our services.
        </Text>
        <Text>
          Some browsers may automatically accept cookies while some can be
          modified to decline cookies or alert you when a website wants to place
          a cookie on your computer. If you do choose to disable cookies, it may
          limit your ability to use our website. For detailed information on the
          cookies and how we use them see our Cookie Policy.
        </Text>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="2xl">
          International Data Transfers
        </Heading>
        <Text>
          Our business is global with affiliates and service providers located
          around the world. As such, Personal Information may be stored and
          processed in any country where we have operations. Also, your Personal
          Data may be transferred to countries which may not have the same data
          protection laws as the country you initially provided your Personal
          Information, but whenever we transfer or transmit your Personal
          Information internationally, we will ensure or take reasonable steps
          to ensure your Personal Information is handled securely in line with
          the applicable data protection laws and standard contractual clauses.
        </Text>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="2xl">
          Amendments to our privacy policy
        </Heading>
        <Text>
          To keep up with legislation and best practices, from time to time, we
          may change, amend or review this Privacy Policy from time to time and
          place any updates on this page. All changes made will be posted on
          this page and where changes will materially affect you, we will notify
          you of this change by placing a notice online or via mail. If you keep
          using our Services, you consent to all amendments of this Privacy
          Policy.
        </Text>
      </Stack>
      <Stack spacing={"5"}>
        <Heading as={"h4"} fontSize="2xl">
          Contact Us
        </Heading>
        <Text>
          All comments, questions, access requests, complaints, and other
          requests regarding the privacy policy should be sent to
          <a href="support@bitmama.io" className="text-green-500">
            {" "}
            support@bitmama.io
          </a>
        </Text>
        <Text>
          We may request additional details from you regarding your complaints
          and keep records of your requests and resolution.
        </Text>
      </Stack>
    </Stack>
    <Text textAlign={"center"} pt="16">
      This Privacy Policy was last updated in January 2022.
    </Text>
  </Box>
);

export default PrivacyPolicies;
