import { SVGProps } from "react";
const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.1986 1.97539C12.4546 1.43395 13.2344 1.4621 13.4507 2.0206L13.9055 3.19473C14.5732 4.91826 16.4927 5.79578 18.233 5.17306L19.4185 4.74884C19.9825 4.54705 20.5139 5.1183 20.272 5.66621L19.7633 6.81806C19.0167 8.50889 19.7535 10.4867 21.4244 11.2769L22.5627 11.8153C23.1041 12.0713 23.076 12.851 22.5175 13.0674L21.3434 13.5222C19.6198 14.1899 18.7423 16.1094 19.365 17.8497L19.7892 19.0352C19.991 19.5991 19.4198 20.1306 18.8719 19.8886L17.72 19.38C16.0292 18.6334 14.0514 19.3702 13.2612 21.0411L12.7228 22.1794C12.4668 22.7208 11.687 22.6927 11.4707 22.1342L11.0158 20.96C10.3482 19.2365 8.42867 18.359 6.68839 18.9817L5.50286 19.4059C4.93894 19.6077 4.4075 19.0365 4.64943 18.4886L5.15805 17.3367C5.90465 15.6459 5.16785 13.6681 3.49696 12.8778L2.91396 14.1106L3.49696 12.8778L2.3587 12.3395C1.81726 12.0834 1.84541 11.3037 2.40391 11.0874L3.57803 10.6325C5.30157 9.96487 6.17909 8.04536 5.55637 6.30508L5.13215 5.11955C4.93036 4.55563 5.50161 4.02419 6.04952 4.26612L7.20137 4.77474C8.8922 5.52135 10.87 4.78454 11.6602 3.11365L12.1986 1.97539Z"
      stroke="#126730"
      stroke-width="2.72727"
    />
  </svg>
);
export default SVGComponent;
