import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Icon
} from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";

const FAQ = ({ faqs }: { faqs: { question: string; answer: string }[] }) => {
  return (
    <Accordion allowMultiple>
      {faqs.map((el, idx) => (
        <AccordionItem key={idx}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton py="4">
                  <Box flex="1" textAlign="left" fontWeight={"bold"}>
                    {el.question}
                  </Box>
                  {isExpanded ? (
                    <Icon as={BiMinus} boxSize="6" />
                  ) : (
                    <Icon as={BsPlus} boxSize="6" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{el.answer}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FAQ;
