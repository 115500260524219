export const newRouteList = [
  {
    title: "features",
    to: "features",
    children: [
      {
        title: "EasyDollar Debit Card",
        to: "virtual-cards"
      },
      {
        title: "Multi-currency Conversion",
        to: "multi-currency-wallets"
      },
      {
        title: "Social Money",
        to: "social-money"
      },
      {
        title: "Money Transfer and Payment",
        to: "money-transfer"
      },
      {
        title: "Virtual Bank Account",
        to: "virtual-bank"
      }
      // {
      //   title: "Stable Currencies",
      //   to: "stable-coin"
      // }
    ]
  },
  {
    title: "pricing",
    to: "pricing"
  },
  {
    title: "FAQs",
    to: "pricing#faqs"
  }
];
