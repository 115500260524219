import {
  // Button,
  Flex,
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text
} from "@chakra-ui/react";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { BiChevronDown } from "react-icons/bi";
import { useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const PricingCard = ({
  title,
  price,
  catchPhrase,
  features,
  colors
}: {
  title: string;
  price: string;
  catchPhrase: string;
  features: string[];
  colors: {
    chakra: string;
    hex: string;
  };
}) => {
  const [featuresCount, setFeaturesCount] = useState(4);

  const featureList = useMemo(() => {
    return features.slice(0, featuresCount);
  }, [featuresCount, features]);

  const setFeaturesCountHandler = () => {
    setFeaturesCount((prev) => {
      return prev === 4 ? features.length : 4;
    });
  };

  return (
    <Stack
      w="96"
      bg={colors.chakra}
      p="4"
      spacing={"4"}
      rounded="2xl"
      position={"relative"}
      pb="20"
      h="fit-content"
      my="10"
      border={"1px"}
      borderColor={colors.chakra}
      color={colors.chakra === "gray.100" ? "gray.800" : "white"}
    >
      <Text>{title}</Text>
      <Stack direction={"row"} alignItems="flex-end" fontWeight={"600"}>
        <Heading>{price}</Heading>
        {price.toLocaleLowerCase() === "free" ? null : (
          <Text pb="1">/year</Text>
        )}
      </Stack>
      <Text>{catchPhrase}</Text>
      {/* <Button
        bg={colors.chakra === "gray.100" ? "success.600" : "white"}
        color={colors.chakra === "gray.100" ? "white" : "success.600"}
        py="6"
      >
        {price === "Free" ? <> Get Started Free</> : <> Get Started</>}
      </Button> */}
      <List spacing={3} pt="4">
        <TransitionGroup>
          {featureList.map((el, idx) => (
            <CSSTransition timeout={300} classNames="price" key={idx}>
              <ListItem display={"inline-flex"} key={idx} className="py-2">
                <ListIcon
                  as={IoMdCheckmarkCircle}
                  color={colors.chakra === "gray.100" ? "success.600" : "white"}
                  mt="1"
                  // size={"24px"}
                  width={"20px"}
                  height={"20px"}
                />
                <Text>{el}</Text>
              </ListItem>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </List>
      <Flex
        bg={colors.hex}
        position={"absolute"}
        w="100%"
        h="12"
        bottom={"0"}
        left="0"
        textAlign={"center"}
        alignItems="center"
        justifyContent={"center"}
        cursor="pointer"
        onClick={setFeaturesCountHandler}
        roundedBottomLeft="2xl"
        roundedBottomRight="2xl"
        fontWeight={"semibold"}
        py="6"
      >
        <Text>See {featuresCount === 4 ? "all" : "less"} Features</Text>
        <Icon as={BiChevronDown} boxSize="6" />
      </Flex>
    </Stack>
  );
};

export default PricingCard;
