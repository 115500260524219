import {
  Box,
  Text,
  Link,
  Stack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

const TermsOfUse = ({ paragraphFontSize = [20] }) => (
  <Box p={6} bgColor="#fffaf0">
    <Box maxW="1000px" mx="auto">
      <Box>
        <Text my={10} textAlign="center" fontWeight="bold" fontSize={32}>
          Terms and Conditions
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            IT IS IMPORTANT THAT YOU READ THROUGH THESE TERMS OF USE CAREFULLY,
            BEFORE USING{" "}
            <Text as="span" fontWeight="bold">
              THE WEBSITE/APPLICATION
            </Text>{" "}
            (the{" "}
            <Box fontWeight="bold" display="inline">
              "SITE"
            </Box>
            ). IF YOU DO NOT ACCEPT THESE TERMS, YOU SHOULD NOT CLICK ON “I
            AGREE”. BITMAMA RECOMMENDS THAT YOU PRINT OUT THE TERMS OF USE
            BEFORE AGREEING TO THEM, TO MAKE SURE YOU ACCEPT THEM WITHOUT
            RESERVATION. IF YOU USE THIS SITE IN ANY WAY WHATSOEVER, YOU ARE
            AGREEING TO ADHERE TO THESE TERMS OF USE, WHICH CONSTITUTE A
            CONTRACT BETWEEN CHANGERA AND YOURSELF.
          </Text>

          <Text fontSize={paragraphFontSize} lineHeight={1.6}>
            These Terms and Conditions (the “Terms”) set forth the rights and
            responsibilities of visitors and customers that use the website,
            services, application programming interfaces (APIs), products, and
            platform relating to services (collectively, the “Services”) made
            available by Changera Technologies Limited and its affiliates
            (“Company,” “We,” “Our” or “Us”). Please read these Terms carefully.
            If you do not agree to these Terms, please do not use the Services.
            If you are using our Services, you are agreeing to be bound to these
            Terms as a customer and you represent and warrant that you have the
            authority to agree to these Terms. In that case, “you” or “User” or
            “your” will refer to your business organization in these Terms.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          Definitions
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            “Third Party” shall mean any individual, association, partnership,
            firm, company, corporation, consultant, Subcontractor, or a
            combination thereof, including joint ventures, that is not a party
            to this Agreement.
          </Text>

          <Text fontSize={paragraphFontSize}>
            “User” shall mean any registered and accredited business or
            organization by Changera to operate and use the Changera Account.
          </Text>

          <Text fontSize={paragraphFontSize}>
            "User Data" shall mean any data, information, documents, or
            materials submitted by the User to the Company prior to or during
            the use of the Site.
          </Text>

          <Text fontSize={paragraphFontSize}>
            “Changera Account” shall mean the wallet account which shall be
            created by Changera, on its changera platform for the benefit of the
            User upon Changera’s approval of the User’s request to create a
            profile on its platform.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          1. Scope
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            The Terms define the conditions under which the User will access and
            utilize the Services. Any use of the Site by the User entails the
            latter’s full and unreserved acceptance of these Terms. The User
            accepts these Terms, acknowledges having reviewed them in their
            entirety, and therefore waives any ability to invoke contradictory
            documentation.
          </Text>

          <Text fontSize={paragraphFontSize}>
            Changera may modify the Terms at any time. The relationship between
            the Parties will always be governed by the most recent version of
            the Terms on the date of the User’s access and use of the Site. In
            the event that these Terms are modified, the User will be asked to
            agree to the new terms and conditions, which will be applicable as
            from the date of that acceptance.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          2. Account Creation
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            Before using the Services, you must: (a) accept and agree to these
            Terms and our{" "}
            <Link
              target="_blank"
              color="green.500"
              textDecoration="none !important"
              href="/legal/terms-and-conditions"
            >
              Privacy Policy
            </Link>
            ; (b) register with us on the Site and create an account
            ("Account"); (c) be duly registered under the relevant and
            applicable laws in your jurisdiction. When you register for an
            Account, you will be required to provide information that will allow
            us to verify your business and the identity and/or the information
            of its promoters ("User Information"), including but not limited to:
          </Text>

          <Box pl={6}>
            <Text fontSize={paragraphFontSize}>
              <Text as="span" mr={4} fontWeight={600}>
                i.
              </Text>{" "}
              Registration documents
            </Text>
            <Text fontSize={paragraphFontSize}>
              <Text as="span" mr={4} fontWeight={600}>
                ii.
              </Text>{" "}
              a valid physical address, phone number
            </Text>
            <Text fontSize={paragraphFontSize}>
              <Text as="span" mr={4} fontWeight={600}>
                iii.
              </Text>{" "}
              bank account information
            </Text>
            <Text fontSize={paragraphFontSize}>
              <Text as="span" mr={4} fontWeight={600}>
                iv.
              </Text>{" "}
              a copy of the photo identification of the promoters or directors
            </Text>
            <Text fontSize={paragraphFontSize}>
              <Text as="span" mr={4} fontWeight={600}>
                v.
              </Text>{" "}
              tax identification information.
            </Text>
          </Box>

          <Text fontSize={paragraphFontSize}>
            You may also be required to provide additional information or
            documentation to allow us to verify your business and/or your
            Account information. We reserve the right to verify the information
            you provide against third-party databases or other sources and you
            authorize us to make such inquiries.
          </Text>

          <Text fontSize={paragraphFontSize}>
            In addition, during the registration process, you may be asked to
            enter your user credentials for your online accounts with
            third-party financial institutions, such as your bank, so that we
            may review your financial transaction information. You expressly
            authorize us to access these accounts and view such information
            solely to perform the Services. The Services will provide metadata
            and other context for the reporting of payments initiated via
            Services API or web platform, available via API, web, or CSV
            download. You are granted a limited, non-exclusive,
            non-transferable, non-sublicensable, revocable right to use such
            reporting for your internal business analysis purposes only, and for
            no other purpose.
          </Text>

          <Text fontSize={paragraphFontSize}>
            Upon your registration on the Site, you will obtain your login
            details as provided by you to enable you to access the services. The
            Site is accessible by you by entering the Username and Password
            (“Login Details”).
          </Text>

          <Text fontSize={paragraphFontSize}>
            Upon successful registration and provision of required documents,
            shall review your application and notify you whether your
            application is successful or rejected. Your application may be
            rejected in the event where you are unable to submit the required
            KYC verification details or for any other reason which may or may
            not be communicated to you.
          </Text>

          <Text fontSize={paragraphFontSize}>
            Where your application is successful, we will create a Changera
            Account for you on our Platform. Upon creation of your Changera
            Account, you will be required to fund the Account from time to time
            for the purpose of your transaction on our Platform. You will be
            able to add more than one user from your organization and access
            levels for each user will depend on your preference.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          3. Payment Notification
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            Changera will update the User on the status of its payment made via
            the platform; once a transaction is completed, Changera will send
            out an email to notify you of such completion. Changera will also
            provide receipts (either via email or the Virtual Account dashboard
            in a format that you can download). The payment’s status of your
            transaction will also be updated to “Failed” if for some reason the
            payment wasn’t completed successfully. Please note that because
            Changera successfully acknowledges a payment process doesn’t mean it
            has been successfully received by the recipient. Changera will not
            be responsible for any errors by you in respect of the accuracy of
            recipient account details and charges from recipient banks. Changera
            will also not the transaction charges if monies are refunded.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          4. Account Security
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            The User is given dedicated, secure access to their account using
            their Login Details. The User is responsible for the password,
            chosen by them at the time of registration, and undertakes not to
            share it with anyone. Changera may not, under any circumstances, be
            held responsible for the consequences of fraudulent use of a
            password by a third party.
          </Text>

          <Text fontSize={paragraphFontSize}>
            Please contact us immediately at{" "}
            <Link
              color="green.500"
              textDecoration="none !important"
              href="mailto:support@changera.co"
            >
              support@changera.co
            </Link>{" "}
            if you believe that your Account has been accessed without your
            authorization, a transaction that you did not authorize has
            occurred, a transaction has been processed incorrectly to or from
            your Account, or your Account information or statements contain an
            error regarding your transaction history (each, an “Error”).
          </Text>

          <Text fontSize={paragraphFontSize}>
            We reserve the right at any time to suspend without notice your
            access to and/or use of the Site to enable us to carry out essential
            emergency and/or urgent maintenance. We’ll also check to be sure
            that the payment is not going to an unsupported or restricted
            region. We will use reasonable commercial endeavours to provide you
            with reasonable advance notice of any scheduled non-emergency
            maintenance. Scheduled non-emergency maintenance will be performed
            outside of Business Hours to the extent reasonably practicable. We
            will use commercially reasonable endeavours to notify you of any
            unplanned downtime of the Site and to resolve the issue as soon as
            reasonably practicable. Changera reserves the right to reject a
            payment for any of these reasons for any or no reason at all.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          5. Confidentiality
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            We both acknowledge that during the course of the transactions
            contemplated by these Terms, a party (the “Disclosing Party”) may
            find it necessary or appropriate to share Confidential Information
            (as defined below) with the other party (the “Receiving Party”). The
            Receiving Party will: (i) not use the Disclosing Party’s
            Confidential Information except for the exercise of its rights or
            performance of its obligations hereunder; (ii) not disclose such
            Confidential Information to any third party, other than its
            employees and consultants who have a “need to know” for the
            Receiving Party to exercise its rights or perform its obligations
            hereunder; and (iii) protect the confidentiality of the Disclosing
            Party’s Confidential Information with the same measures as the
            Receiving Party uses to protect its own Confidential Information,
            but using no less than reasonable measures. If the Receiving Party
            is required by law to make any disclosure of such Confidential
            Information, the Receiving Party will first give written notice of
            such requirement to the Disclosing Party and will permit the
            Disclosing Party the opportunity to seek to protect its interests in
            the Confidential Information, and provide full cooperation to the
            Disclosing Party in seeking to obtain such protection. Information
            will not be deemed Confidential Information hereunder if such
            information: (1) is known or becomes known (independently of
            disclosure by the Disclosing Party) to the Receiving Party prior to
            receipt from the Disclosing Party from a source other than one
            having an obligation of confidentiality to the Disclosing Party; (2)
            becomes publicly known, except through a breach hereof by the
            Receiving Party; or (3) is independently developed by the Receiving
            Party without any use of or reference to the Disclosing Party’s
            Confidential Information.
          </Text>

          <Text fontSize={paragraphFontSize}>
            <Text as="span" fontWeight="bold">
              "Confidential Information"
            </Text>{" "}
            means any information provided by one party to the other and
            concerning such Disclosing Party’s business or operations including,
            but not limited to, all tangible, intangible, visual, electronic,
            now existing or future information such as (a) trade secrets; (b)
            financial information, including pricing of the Services; (c)
            technical information, including research, development, procedures,
            algorithms, data, designs, code, and knowhow; (d) business
            information, including operations, planning, marketing interests,
            and products; and (e) all other information which would, due to the
            nature of the information disclosed or the circumstances surrounding
            such disclosure, appear to a reasonable person to be confidential or
            proprietary.
          </Text>

          <Text fontSize={paragraphFontSize}>
            You acknowledge and agree that the Services are not designed with
            security and access management for processing any Protected
            Information, and, unless otherwise agreed to by Changera in writing,
            you agree that Account Data will not include any Protected
            Information. “Protected Information” means (i) any patient medical
            or other health information; (ii) Personally Identifiable
            Information, as defined in the Nigeria Data Protection Regulation
            (NDPR) and General Data Protection Regulation (GDPR) or (iv)
            personal data revealing racial or ethnic origin, political opinions,
            religious or philosophical beliefs, trade union membership, genetic
            data, biometric data, or data concerning a natural person’s sex life
            or sexual orientation.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          6. User Obligations
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            6.1 Obligations relating to the use of the Site, the User agrees to:
            submit complete, accurate information and to update that information
            in event of any change refrain from using the Site for fraudulent
            ends or any other purposes not mentioned in this Terms;
          </Text>
          <Stack pl={6}>
            <Text fontSize={paragraphFontSize}>
              a&#41; use the Site for committing fraud, embezzlement, money
              laundering, or for any unlawful and/or illegal purposes;
            </Text>
            <Text fontSize={paragraphFontSize}>
              b&#41; to reproduce, duplicate, copy, sell, resell or exploit any
              portion of the Site;
            </Text>
            <Text fontSize={paragraphFontSize}>
              c&#41; upload, post, email, transmit or otherwise make available
              any content that is unlawful, harmful, threatening, abusive,
              harassing, tortuous, defamatory, vulgar, obscene, libelous,
              invasive of another’s privacy, hateful, or racially, ethnically or
              otherwise objectionable through the Site;
            </Text>
            <Text fontSize={paragraphFontSize}>
              d&#41; use the Site to harm or injure any third party;
            </Text>
            <Text fontSize={paragraphFontSize}>
              e&#41; impersonate any person or entity, on the Site;
            </Text>
            <Text fontSize={paragraphFontSize}>
              f&#41; forge headers or otherwise manipulate identifiers to
              disguise the origin of any content transmitted through the Site;
            </Text>
            <Text fontSize={paragraphFontSize}>
              g&#41; upload, post, email, transmit or otherwise make available
              any content that you do not have a right to make available under
              any law or under contractual or fiduciary relationships (such as
              inside information, proprietary and confidential information
              learned or disclosed as part of employment relationships or under
              nondisclosure agreements);
            </Text>
            <Text fontSize={paragraphFontSize}>
              h&#41; upload, post, email, transmit or otherwise make available
              on the Site, any content that infringes any patent, trademark,
              trade secret, copyright, or other proprietary rights of any party;
            </Text>
            <Text fontSize={paragraphFontSize}>
              i&#41; upload, post, email, transmit or otherwise make available
              on the Site, any unsolicited or unauthorized advertising,
              promotional materials, "junk mail," "spam", "chain letters,"
              "pyramid schemes," or any other form of solicitation;
            </Text>
            <Text fontSize={paragraphFontSize}>
              j&#41; upload, post, email, transmit or otherwise make available
              on the Site, any material that contains software viruses or any
              other computer code, files, or programs designed to interrupt,
              destroy or limit the functionality of any computer software or
              hardware or telecommunications equipment;
            </Text>
            <Text fontSize={paragraphFontSize}>
              k&#41; disrupt the normal flow of or otherwise act in a manner
              that negatively affects other user’s ability to engage in
              real-time exchanges;
            </Text>
            <Text fontSize={paragraphFontSize}>
              l&#41; interfere with or disrupt the Site or servers or networks
              connected to the Site, or disobey any requirements, procedures,
              policies, or regulations of networks connected to the Site;
            </Text>
            <Text fontSize={paragraphFontSize}>
              m&#41; intentionally or unintentionally violate any applicable
              local, state, national or international laws and any regulations
              having the force of law; and
            </Text>
            <Text fontSize={paragraphFontSize}>
              n&#41; refrain from creating multiple accounts for the purpose of
              accessing the Site and using the features by artificially renewing
              any trial period offered by Changera.
            </Text>
          </Stack>
          <Text fontSize={paragraphFontSize}>
            6.2 Obligations relating to the content posted by the User in the
            Site, the User and/or the Client alone will be responsible for the
            content they publish on the Site, namely any content (data,
            databases, texts, photos, videos, etc.) that they may post on the
            Site while using its features. They guarantee that their content
            will not constitute disorderly conduct or offensive material,
            infringe upon any third-party rights (namely in connection with
            their intellectual property, privacy, reputation, or image), or
            contain any denigrating, defamatory, or discriminating remarks about
            Changera, and that it will comply with current laws and regulations.
            The User will also refrain from posting advertising and/or
            promotional content in the Site, that has no connection to the
            purpose of the Site or the Company.
          </Text>
          <Text fontSize={paragraphFontSize}>
            6.3 Any violation of any of the User’s obligations, as defined by
            Articles 6.1 and 6.2, could result in the closure of their account
            by Changera. If Changera closes the User’s account for failure to
            comply with their obligations under this Terms, the Company shall
            have the right to terminate any and all licenses given to the User.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          7. Intellectual Property
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            <Text as="span" textDecoration="underline">
              Ownership License:
            </Text>{" "}
            Changera owns all rights, titles, and interests in and to the
            Services including all related intellectual property rights. Subject
            to your compliance with these Terms, you are granted a limited,
            non-exclusive, non-transferable, non-sublicensable, revocable right
            to use the Services for your internal business purposes only, and
            for no other purpose. All of our rights not expressly granted by us
            to you pursuant to these Terms are retained by Us.
          </Text>

          <Text fontSize={paragraphFontSize}>
            <Text as="span" textDecoration="underline">
              Maintenance & Support:
            </Text>{" "}
            You acknowledge that while the Company may, at its sole discretion,
            provide maintenance and support for the Site from time to time, the
            Company shall have no specific obligation whatsoever to furnish such
            services to you.
          </Text>

          <Text fontSize={paragraphFontSize}>
            <Text as="span" textDecoration="underline">
              Updates/Upgrades:
            </Text>{" "}
            We may launch new updates/upgrades for the Site, you may subscribe
            to the same through the Online Stores. In the event, you choose not
            to update/upgrade the App, certain features or functionality shall
            not be accessible to you.
          </Text>

          <Text fontSize={paragraphFontSize}>
            <Text as="span" textDecoration="underline">
              Feedback:
            </Text>{" "}
            We welcome your feedback. If you provide recommendations,
            suggestions, improvements, or other feedback (collectively,
            “Feedback”), you agree that we will have a royalty- free, worldwide,
            perpetual and irrevocable license to use and incorporate such
            Feedback into the Services without restriction and without any
            obligation or compensation to you or any third party.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          8. Limitation of Liability
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            IN NO EVENT WILL WE OR OUR LICENSORS OR SUPPLIERS HAVE ANY LIABILITY
            TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS OR REVENUES OR FOR
            ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER, OR PUNITIVE
            DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER
            THEORY OF LIABILITY, AND WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR OR OUR LICENSORS
            OR SUPPLIERS’ TOTAL AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO
            THESE TERMS, USE OF THE SERVICES BY YOU, OR ANY SERVICES TERM
            (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY
            (INCLUDING NEGLIGENCE)) EXCEED THE LESSER OF THE AMOUNT YOU PAID TO
            US FOR THE SERVICES IN THE THREE (3) MONTHS PRECEDING THE EVENT
            GIVING RISE TO THE CLAIM OR $1,000 (WHICHEVER IS LESSER). THE
            FOREGOING WILL NOT LIMIT YOUR PAYMENT OBLIGATIONS UNDER THESE TERMS.
            THE FOREGOING WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE
            LAW. YOU AGREE THAT THE TERMS IN THIS LIMITATION OF LIABILITY
            SECTION ALLOCATE THE RISKS BETWEEN THE PARTIES, AND THE PARTIES HAVE
            RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO
            THESE TERMS.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          9. Force Majeure
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            We shall not be liable or responsible for any failure to perform or
            delay in performance of, any of our obligations under these Terms
            that are caused by events outside of our reasonable control (“Force
            Majeure Event”).
          </Text>
          <Text fontSize={paragraphFontSize}>
            A Force Majeure Event includes in particular (but without
            limitation) the following: strikes, lockouts or other industrial
            action; civil commotion, riot, invasion, terrorist attack or threat
            of terrorist attack, war (whether declared or not) or threat or
            preparation for war; fire, explosion, storm, flood, earthquake,
            subsidence, epidemic, pandemic or other natural disaster or Act of
            God; nuclear, chemical or biological contamination or sonic boom;
            the impossibility of the use of public or private telecommunications
            networks; the acts, decrees, legislation, regulations or
            restrictions of any government; the non-delivery or late delivery of
            products or Service to us by third parties; or any other event
            beyond a Party’s reasonable control.
          </Text>
          <Text fontSize={paragraphFontSize}>
            Our performance under these Terms and Conditions is deemed to be
            suspended for the period that the Force Majeure Event continues and
            we will have an extension of time for performance for the duration
            of that period.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          10. Representations and Warranties
        </Text>
        <Stack spacing={10} mb={10}>
          <Box>
            <Text fontSize={paragraphFontSize}>
              10.1. Mutual Representations and Warranties
            </Text>
            <Text fontSize={paragraphFontSize}>
              Each party represents and warrants to the other that:
            </Text>
            <Box pl={6}>
              <Text fontSize={paragraphFontSize}>
                (a) this Agreement has been duly executed and delivered and
                constitutes a valid and binding agreement enforceable against
                such party in accordance with its terms;
              </Text>
              <Text fontSize={paragraphFontSize}>
                (b) no authorization or approval from any third party is
                required in connection with such party’s execution, delivery, or
                performance of this Agreement; and
              </Text>
              <Text fontSize={paragraphFontSize}>
                (c) the execution, delivery, and performance of this Agreement
                does not violate the laws of any jurisdiction or the terms or
                conditions of any other agreement to which it is a party or by
                which it is otherwise bound.
              </Text>
            </Box>
          </Box>
          <Box>
            <Text fontSize={paragraphFontSize}>
              10.2. Changera Representations and Warranties
            </Text>
            <Text fontSize={paragraphFontSize}>
              Changera represents and warrants to the User that:
            </Text>
            <Box pl={6}>
              <Text fontSize={paragraphFontSize}>
                (a) Changera has sufficient right, title, and interest in the
                Services to license the Services to User in accordance with this
                Agreement, and that entering into and carrying out the terms and
                conditions of this Agreement will not violate or constitute a
                breach of any agreement binding upon Changera;
              </Text>
              <Text fontSize={paragraphFontSize}>
                (b) the Support Services will be performed in a professional and
                workmanlike manner and will be of a grade, nature, and quality
                that meets prevailing standards in the software-as-a-service
                industry;
              </Text>
              <Text fontSize={paragraphFontSize}>
                (c) to Changera’s knowledge as of the Effective Date, the User’s
                use of the Services in accordance with this Agreement will not
                infringe, misappropriate, or otherwise violate any third-party
                intellectual property or other proprietary rights.
              </Text>
            </Box>
          </Box>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          11. Disclaimer
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES STATED IN THIS
            AGREEMENT, BITMAMA MAKES NO ADDITIONAL REPRESENTATION OR WARRANTY OF
            ANY KIND WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF
            LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER, INCLUDING ALL
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, QUALITY, ACCURACY, TITLE, AND NON-INFRINGEMENT. BITMAMA
            DOES NOT WARRANT AGAINST INTERFERENCE WITH THE ENJOYMENT OF THE
            SERVICES OR ANY DELIVERABLES, OR AGAINST INFRINGEMENT, EXCEPT AS
            EXPRESSLY STATED IN THIS AGREEMENT. BITMAMA DOES NOT WARRANT THAT
            THE SERVICES OR ANY DELIVERABLES ARE ERROR-FREE OR THAT OPERATION OF
            THE SERVICES OR DELIVERABLES WILL BE SECURE OR UNINTERRUPTED.
            BITMAMA EXERCISES NO CONTROL OVER AND EXPRESSLY DISCLAIMS ANY
            LIABILITY ARISING OUT OF OR BASED UPON THE CLIENT’S USE OF THE
            SERVICES AND DELIVERABLES. IF BITMAMA IS PROVIDING PAYMENTS SERVICES
            PURSUANT TO THE AGREEMENT, BITMAMA MAKES NO REPRESENTATIONS OR
            WARRANTIES REGARDING THE AMOUNT OF TIME NEEDED TO COMPLETE PAYMENT
            REQUESTS THROUGH THE PAYMENTS SERVICES, AS SUCH PAYMENTS ARE
            DEPENDENT UPON MANY FACTORS OUTSIDE OF BITMAMA’S CONTROL, INCLUDING
            BUT NOT LIMITED TO PAYMENT SETTLEMENT TIMES, PAYMENT HOLDS, BANK
            INFRASTRUCTURE OUTAGES OR OTHER DELAYS IN THE BANKING SYSTEM AND
            BANK PAYMENT.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          12. Notices
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            Notices. Any notice required or permitted under the terms of this
            Agreement or required by law must be in writing and will be deemed
            effective: (i) upon delivery when delivered in person; (ii) upon
            transmission when delivered by verified facsimile transmission or
            e-mail with delivery receipt; (iii) when delivered by a recognized
            national or international overnight courier service with some form
            of tracking mechanism; or (iv) when delivered by certified or
            registered mail with return receipt. Either party may change its
            address for notices by notice to the other party given in accordance
            with this Section. All Notices should be sent to{" "}
            <Link
              color="green.500"
              href="mailto:support@changera.co"
              textDecoration="none !important"
            >
              support@Changera.io
            </Link>
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          13. Waiver
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            If we fail at any time to insist upon strict performance of any of
            your obligations under any of these Terms, or if we fail to exercise
            any of the rights or remedies to which we are entitled under these
            Terms, this shall not constitute a waiver of such rights or remedies
            and shall not relieve you from compliance with such obligations. A
            waiver by us of any default shall not constitute a waiver of any
            subsequent default. No waiver by us of any of these Terms shall be
            effective unless it is expressly stated to be a waiver and is
            communicated to you in writing in accordance with these Terms.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          14. No Partnership or Agency
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            Each party is an independent contractor, and neither party has any
            authority to act on behalf of the other. Neither Party will
            represent itself as agent, servant, franchisee, joint venturer, or
            legal partner of the other. We are entering into these Terms and
            Conditions as principal and not as agent for any other Affiliate
            company and claims under these Terms and Conditions may be brought
            only against us and not against any of our Affiliates.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          15. Severability
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            If any term, condition, or provision in this Agreement is found to
            be invalid, unlawful, or unenforceable to any extent, the parties
            will endeavor in good faith to agree to amendments that will
            preserve, as far as possible, the intentions expressed in this
            Agreement. If the parties fail to agree on an amendment, the invalid
            term, condition, or provision will be severed from the remaining
            terms, conditions, and provisions of this Agreement, which will
            continue to be valid and enforceable to the fullest extent permitted
            by law.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          16. Unfair Competition
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            You shall not use the Site, documentation or any other materials
            provided by us from time to time, including but not being limited to
            our Intellectual Property Rights or Confidential Information, to
            build a competitive product, service, or Site or to benchmark with a
            product or service not provided by us.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          17. Change to terms
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is a material, Changera will
            make reasonable efforts to provide at least 30 days notice prior to
            any new terms taking effect. What constitutes a material change will
            be determined at Our sole discretion.
          </Text>
          <Text fontSize={paragraphFontSize}>
            By continuing to access or use Our Service after those revisions
            become effective, you agree to be bound by the revised terms. If You
            do not agree to the new terms, in whole or in part, please stop
            using the Site and the Services.
          </Text>
        </Stack>

        <Text mb={2} textTransform="uppercase" fontWeight="bold" fontSize={22}>
          18. Termination
        </Text>
        <Stack spacing={10} mb={10}>
          <Text fontSize={paragraphFontSize}>
            The Company reserves its rights to terminate these Terms and
            Conditions in the event:
          </Text>
          <UnorderedList pl={10}>
            <ListItem fontSize={paragraphFontSize}>
              you breach any provision of these Terms;
            </ListItem>
            <ListItem fontSize={paragraphFontSize}>
              the Company is required to do so under law;
            </ListItem>
            <ListItem fontSize={paragraphFontSize}>liquidation;</ListItem>
            <ListItem fontSize={paragraphFontSize}>
              the Company chooses to discontinue the Service being offered or
              discontinue to operate the Site;
            </ListItem>
          </UnorderedList>
          <Text fontSize={paragraphFontSize}>
            Upon termination of these Terms, the rights and licenses granted to
            you under these Terms shall cease to exist, and you must forthwith
            stop using the Service.
          </Text>
        </Stack>
      </Box>
    </Box>
  </Box>
);

export default TermsOfUse;
