import { Box } from "@chakra-ui/react";
import Content from "./content";
import Footer from "./footer";
import Header from "./header ";

const NewHomeLayout = (): JSX.Element => {
  return (
    <Box>
      <Header />
      <Content />
      <Footer />
    </Box>
  );
};

export default NewHomeLayout;
