import { Box, Button, Heading, Image, Stack } from "@chakra-ui/react";
import featureBack from "app/assets/svg/tapeBack.svg";
import img from "app/assets/png/heroHeadNew.png";
import GetStartedLink from "views/components/get-started-link";
export const NewHero = () => {
  return (
    <div className="bg-[#0A0F1F] pt-16 md:pt-20">
      <Stack
        pt={[6, 8, 12, 16]}
        px={["6", "6", "6", "7.5rem"]}
        py={["6", "6", "6", "20"]}
        pb={["16", "16", "16", "20"]}
        position="relative"
        justifyContent="center"
        className="lg:min-h-[95svh]"
      >
        <Stack
          zIndex={"99"}
          direction={["row"]}
          alignItems="center"
          display={["flex"]}
          py={6}
          flexDirection={["column", "column", "row", "row", "row"]}
          justifyContent="space-between"
          gap={[3, 6, 8]}
        >
          <Box flexBasis={["50%"]}>
            <Heading
              color={"white"}
              fontWeight={700}
              lineHeight="1.2"
              fontFamily={"'Bricolage Grotesque Variable'"}
              fontSize={["4xl", "4xl", "5xl", "6xl", "7xl"]}
              pb="6"
              w={["full", "full", "full", "30rem", "36rem"]}
            >
              <span className="text-[#FFDD58]">Simplify</span> your Global
              Financial Journey
            </Heading>

            <div>
              <p className="py-1 text-lg md:text-xl text-[#C7C7DC]">
                With Changera, you can easily request and make payments
                worldwide and acquire a global dollar card. <br /> Additionally,
                conveniently find event or flight tickets and manage your
                expenses seamlessly, all within a single app.
              </p>
            </div>

            <GetStartedLink>
              <Button
                bgColor={"#40BB6B"}
                fontWeight="semibold"
                fontSize={"20px"}
                borderRadius={"full"}
                color={"#24242A"}
                px="8"
                py="6"
                className="my-5 lg:my-10"
                _hover={{ boxShadow: "0px 4px 22px rgba(8, 12, 26, 0.25)" }}
              >
                Get Changera
              </Button>
            </GetStartedLink>
          </Box>
          <Box
            flexBasis={["50%"]}
            flexShrink="0"
            paddingTop={["6", "8", "0", "0", "0"]}
          >
            <Image src={img} w={["100%", "100%"]} margin={"auto"} />
          </Box>
        </Stack>

        {/* <Box className="absolute bottom-0 left-0 w-full h-16 bg-white slant-clip-path"></Box> */}

        <Image
          src={featureBack}
          display={["none", "none", "none", "block", "block"]}
          position={["absolute"]}
          // zIndex="-1"
          opacity={"1"}
          margin="auto"
          bottom={["0"]}
          right="0"
        />
      </Stack>
    </div>
  );
};
