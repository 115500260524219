import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { AiOutlineLine } from "react-icons/ai";
import featureBack from "app/assets/svg/feature-back.svg";
import GetStartedLink from "./get-started-link";
const FeaturePage = ({
  title,
  features,
  actionLine,
  img,
  catchPhrase
}: {
  title: string;
  features: string[];
  actionLine: string;
  img: string;
  catchPhrase: string;
}) => {
  return (
    <div>
      <Stack
        px={["6", "6", "6", "7.5rem"]}
        py={["6", "6", "6", "20"]}
        position="relative"
      >
        <Stack
          direction={["row"]}
          alignItems="center"
          display={["none", "none", "flex"]}
        >
          <Box flexBasis={["40%", "40%", "40%", "50%"]}>
            <Stack direction={"row"} color={"blue.500"} alignItems="center">
              <Icon as={AiOutlineLine} w="10" />
              <Text textTransform={"uppercase"}>{catchPhrase}</Text>
            </Stack>
            <Heading
              fontWeight={"700"}
              lineHeight="1.2"
              fontFamily={"'Bricolage Grotesque Variable'"}
              fontSize={["24px", "24px", "32px", "40px"]}
              pb="6"
              w={["full", "full", "full", "30rem", "36rem"]}
            >
              {title}
            </Heading>
            <UnorderedList>
              {features.map((el, idx) => (
                <ListItem key={idx} py="1" fontWeight={500}>
                  {el}
                </ListItem>
              ))}
            </UnorderedList>
            <GetStartedLink>
              <Button
                colorScheme={"primary"}
                px="10"
                py="6"
                w={["full", "auto"]}
                mt="10"
                _hover={{ boxShadow: "0px 4px 22px rgba(8, 12, 26, 0.25)" }}
              >
                {actionLine}
              </Button>
            </GetStartedLink>
          </Box>
          <Box flexBasis={["60%", "60%", "60%", "50%"]} flexShrink="0">
            <Image src={img} w="90%" margin={"auto"} />
          </Box>
        </Stack>

        <Stack display={["flex", "flex", "none"]}>
          <Stack direction={"row"} color={"blue.500"} alignItems="center">
            <Icon as={AiOutlineLine} w="10" />
            <Text textTransform={"uppercase"}>{catchPhrase}</Text>
          </Stack>
          <Heading
            fontWeight={"black"}
            lineHeight="1.2"
            fontFamily={"'Bricolage Grotesque Variable'"}
            fontSize="24px"
            textAlign={["left", "left"]}
            w={"full"}
          >
            {title}
          </Heading>
          <UnorderedList>
            {features.map((el, idx) => (
              <ListItem key={idx} py="1">
                {el}
              </ListItem>
            ))}
          </UnorderedList>
          <GetStartedLink>
            <Button
              alignSelf={"center"}
              colorScheme={"primary"}
              px="24"
              py="6"
              w={["full", "fit-content"]}
              m="auto"
              mt="10"
            >
              {actionLine}
            </Button>
          </GetStartedLink>
          <Image src={img} w="full" py="4" />
        </Stack>

        <Image
          src={featureBack}
          className="hidden lg:block"
          position={[
            "relative",
            "relative",
            "relative",
            "relative",
            "absolute"
          ]}
          zIndex="-1"
          opacity={"1"}
          // w={["100%", "100%", "96", "96", "auto"]}
          // h={["auto", "auto", "auto", "auto", "140%"]}
          margin="auto"
          bottom={["0"]}
          left="0"
        />
      </Stack>
    </div>
  );
};

export default FeaturePage;
