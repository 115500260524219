import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Image,
  Stack,
  StackDivider,
  Text
} from "@chakra-ui/react";
import changeraLogo from "app/assets/svg/whiteLogoIcon.svg";
import ChangeraFooterLogo from "app/assets/png/ChangeraFooterLogo.png";

// import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import GetStartedLink from "views/components/get-started-link";
const Footer = () => {
  return (
    <div className="pt-12 md:pt-16 lg:pt-24">
      <Box
        px={["6", "6", "6", "12", "16"]}
        py={["10", "10", "16", "16"]}
        className="bg-[#FFDD58]"
        mx={["6", "10", "12", "10rem"]}
        bgColor={"#FFDD58"}
        borderRadius="40px"
        zIndex={"10"}
        position="relative"
      >
        <div className="text-center text-[#212327]">
          <p className="text-5xl md:text-6xl font-bricolage font-black">
            What are you waiting for?
          </p>
          <p className="max-w-xl text-lg md:text-xl mt-6 mx-auto">
            Live, work, spend and earn from anywhere in the world while you rest
            assured that your money can be accessed at the tap of a button.{" "}
            Think Limitless; think Changera.
          </p>
          <div className="flex justify-center">
            <GetStartedLink>
              <Button
                bgColor={"#141D3E"}
                fontWeight="semibold"
                borderRadius={"full"}
                color={"#FCFDFD"}
                textTransform="capitalize"
                px="8"
                fontSize={"20px"}
                py="8"
                className="my-6 md:my-8"
                _hover={{ boxShadow: "0px 4px 22px rgba(8, 12, 26, 0.25)" }}
              >
                Download Changera
              </Button>
            </GetStartedLink>
          </div>
        </div>
      </Box>
      <Stack
        mt={["-30%", "-25%", "-20%", "-15%", "-10%"]}
        as={"footer"}
        px={["6", "6", "6", "7.5rem"]}
        py={["16", "16", "16", "24"]}
        color={"white"}
        background="#0A0E1F"
        position={"relative"}
        roundedTop="40px"
        zIndex={"3"}
      >
        <Box maxW={"1600px"} mx="auto">
          <Stack
            pt={[28, 32, 36, 40, 44]}
            direction={["column", "column", "row"]}
            spacing={["8", "8", "auto"]}
            justifyContent="space-between"
            gap={[4, 6]}
          >
            <Box>
              <Image src={changeraLogo} h="10" />
            </Box>
            <Stack color={"#E4E4EE"} fontSize={"sm"} gap={1}>
              <Heading
                fontFamily={"'Urbanist Variable'"}
                fontWeight="bold"
                fontSize={"md"}
              >
                Browse
              </Heading>
              <Text fontSize={"sm"} color="#E4E4EE" as={Link} to="virtual-bank">
                Virtual Accounts
              </Text>
              <Text
                fontSize={"sm"}
                color="#E4E4EE"
                as={Link}
                to="virtual-cards"
              >
                Virtual Cards
              </Text>
              <Text fontSize={"sm"} color="#E4E4EE" as={Link} to="social-money">
                Payment & Transfer
              </Text>
              <Text as={Link} to="multi-currency-wallets">
                Currency Conversion
              </Text>{" "}
              {/* <Text fontSize={"sm"} color="#E4E4EE" as={Link} to="stable-coin">
                Stable Currencies
              </Text> */}
              {/* <Text fontSize={"sm"} color="#E4E4EE">
                Shipping
              </Text> */}
            </Stack>
            <Stack color={"#E4E4EE"} fontSize={"sm"} gap={1}>
              <Heading
                fontFamily={"'Urbanist Variable'"}
                fontWeight="bold"
                fontSize={"md"}
              >
                Company
              </Heading>
              <Text>About Us</Text>
              <Text as={Link} to="pricing">
                Pricing
              </Text>
              <HashLink to="/pricing#faqs">
                <Text as="a" href="/pricing#faqs">
                  FAQs
                </Text>
              </HashLink>
              <Text fontSize={"sm"} color="#E4E4EE">
                Careers
              </Text>
              <Text
                fontSize={"sm"}
                color="#E4E4EE"
                as="a"
                href="https://blog.changera.co/"
                target={"_blank"}
              >
                Blog
              </Text>
            </Stack>
            <Stack color={"#E4E4EE"} fontSize={"sm"} gap={1}>
              <Heading
                fontFamily={"'Urbanist Variable'"}
                fontWeight="bold"
                fontSize={"md"}
              >
                Follow
              </Heading>
              <Text fontSize={"sm"} color="#E4E4EE" as={Link} to="business">
                <a
                  href="https://www.linkedin.com/company/changerainc/mycompany/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Linkedin
                </a>
              </Text>
              <Text fontSize={"sm"} color="#E4E4EE" as={Link} to="/">
                <a
                  href="https://twitter.com/ChangeraApp"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </Text>
              <Text fontSize={"sm"} color="#E4E4EE" as={Link} to="/">
                <a
                  href="https://www.instagram.com/changeraapp/?utm_medium=copy_link"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </Text>
              <Text fontSize={"sm"} color="#E4E4EE" as={Link} to="/">
                <a
                  href="https://www.facebook.com/changeraapp?_rdc=2&_rdr"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </Text>
            </Stack>
            <Stack
              color={"#E4E4EE"}
              fontSize={"20px"}
              gap={1}
              flexBasis={["auto", "auto", "auto", "auto", "25%"]}
            >
              <Heading
                fontFamily={"'Urbanist Variable'"}
                fontWeight="bold"
                fontSize={"md"}
              >
                Sign up to receive newsletters and stay on top of every update
              </Heading>
              <Input
                mt={2}
                placeholder="Email address"
                borderRadius={"full"}
                w="full"
                h="48px"
                border="1px solid #353B47"
                bgColor={"#1F1F2B"}
              />
            </Stack>
          </Stack>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between py-6 md:py-12 lg:py-16 gap-4">
            <Flex
              direction={["column", "column", "row"]}
              gap={["2", "2", "8"]}
              justifyContent="center"
            >
              <Link to="privacy-policies">
                <Text fontSize={"sm"}>Privacy Policy</Text>
              </Link>
              <Link to="terms-of-use">
                <Text fontSize={"sm"}>Terms of Use</Text>
              </Link>
              <Link to="cookies-policies">
                <Text fontSize={"sm"}>Cookies Policies</Text>
              </Link>
            </Flex>
            <Text color={"#E4E4EE"}>
              {" "}
              All rights reserved &copy; {new Date().getFullYear()} Changera
            </Text>
          </div>
          <StackDivider bg={"rgba(202, 203, 203, 0.3)"} w="full" h="1px" />
          <Text color={"#E4E4EE"} pt={["2", "2", "8"]} textAlign={"center"}>
            Changera Technologies Limited, Canada, is registered and regulated
            by the Financial Transactions and Report Analysis Centre of Canada
            as a Money Service Business. MSB registration number: M23107718.
            Changera Technologies Limited, Canada.
            <br /> <br />
            Changera Global offers its products and services in partnership with
            licensed transmitters in their respective jurisdictions. All
            trademarks and brand names belong to their respective owners. Use of
            these trademarks and brand names do not represent endorsement by or
            association with Changera Global. <br /> <br />
            IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To
            help the government fight the funding of terrorism and money
            laundering activities, federal law requires all financial
            institutions to obtain, verify, and record information that
            identifies each person who opens an Account. What this means for
            you: When you open an Account, we will ask for your name, address,
            date of birth, and other information that will allow us to identify
            you. We may also ask to see a copy of your driver’s license and/or
            International Passport.
            <br />
          </Text>
        </Box>
        <Image
          src={ChangeraFooterLogo}
          w="full"
          className="absolute bottom-0 left-0 -z-10"
        />
      </Stack>
    </div>
  );
};

export default Footer;
