import { Helmet } from "react-helmet";
// import { AnimatedFeature } from "views/sections/new-home/animated-feature";
import { GetStarted } from "views/sections/new-home/get-started";
import { GridFeatures } from "views/sections/new-home/grid-features";
// import { HoriAnim } from "views/sections/new-home/horizontal-animated-feature";
import { NewHero } from "views/sections/new-home/new-hero-section";
import { Pricing } from "views/sections/new-home/pricing";
import Products from "views/sections/new-home/products";
import { ScrollDownload } from "views/sections/new-home/scroll-download";
// import TempProductsAnim from "views/sections/new-home/temp-anim";

const NewHome = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Cross-border payments across Nigeria, Ghana, Kenya, Canada etc. Get Virtual cards for all mastercard payments, Book Flights, Buy/Sell Event tickets and more on Changera"
        ></meta>
        <title>
          Changera - Send and Spend money across borders from/to Africa
        </title>
        <link rel="canonical" href="https://changera.co" />
      </Helmet>
      <NewHero />
      <ScrollDownload />
      {/* <AnimatedFeature /> */}
      {/* <TempProductsAnim /> */}
      <Products />
      <GetStarted />
      {/* <HoriAnim /> */}
      <GridFeatures />
      <Pricing />
    </div>
  );
};

export default NewHome;
