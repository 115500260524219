import { RouteObject } from "react-router-dom";
import Dashboard from "views/layout/home-layout";
import NewHomeLayout from "views/layout/new-layout";
import DashboardApp from "views/pages/app";
import Cards from "views/pages/cards";
import CookiePolicies from "views/pages/cookie-policies";
import NewHome from "views/pages/new-home";
import Pricing from "views/pages/pricing";
import LegalPrivacyPolicy from "views/pages/privacy-policies";
import SocialMoney from "views/pages/social-money";
import TermsOfUse from "views/pages/terms-of-use";
import Transfers from "views/pages/transfers";
import VirtualBank from "views/pages/virtual_banks";
import Wallets from "views/pages/wallets";

const routes: RouteObject[] = [
  {
    element: <Dashboard />,
    children: [
      // {
      //   element: <Home />,
      //   path: ""
      // },
      {
        element: <Cards />,
        path: "virtual-cards"
      },
      {
        element: <Wallets />,
        path: "multi-currency-wallets"
      },
      // {
      //   element: <Events />,
      //   path: "events"
      // },
      // {
      //   element: <SingleEvent />,
      //   path: "events/:sign/:id"
      // },
      // {
      //   element: <SingleSlugEvent />,
      //   path: "event/:slug"
      // },
      {
        element: <SocialMoney />,
        path: "social-money"
      },
      {
        element: <Transfers />,
        path: "money-transfer"
      },
      {
        element: <VirtualBank />,
        path: "virtual-bank"
      },
      // {
      //   element: <StableCoin />,
      //   path: "stable-coin"
      // },
      {
        element: <Pricing />,
        path: "pricing"
      },
      {
        element: <LegalPrivacyPolicy />,
        path: "privacy-policies"
      },
      {
        element: <TermsOfUse />,
        path: "terms-of-use"
      },
      {
        element: <CookiePolicies />,
        path: "cookies-policies"
      },
      {
        element: <DashboardApp />,
        path: "app"
      }
    ]
  },
  {
    element: <NewHomeLayout />,
    children: [
      {
        element: <NewHome />,
        path: ""
      }
    ]
  }
];

export default routes;
