import { useEffect } from "react";
import Loader from "views/components/loader";

const DashboardApp = () => {
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const url = isMobile
      ? navigator.userAgent.match(/Android/)
        ? "https://play.google.com/store/apps/details?id=com.bitmama.changera&hl=en&gl=US&pli=1"
        : "https://apps.apple.com/ng/app/changera/id1552433839"
      : "https://changera.co/";
    window.location.replace(url);
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
};

export default DashboardApp;
