import FeaturePage from "views/components/feature-page";
import img from "app/assets/png/transfer_and_payment.png";
import { Helmet } from "react-helmet";

const features = [
  "Withdraw seamlessly to your local bank account or Mobile Money (MoMo) wallet.",
  "Make cheap local transfers to bank accounts and MoMo wallets.",
  "Pay anyone across Africa hassle-free. Send money straight to local bank accounts or MoMo wallets across Africa.",
  "Send funds globally. Make SEPA / ACH / SWIFT payments easily."
];

const Transfers = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Transfer Dollars, Pounds, Euros etc globally and at low transfer fees. Send money to local and international accounts faster on the Changera app."
        ></meta>
        <title>Fast local and international transfers on Changera</title>
        <link rel="canonical" href="https://changera.co/money-transfer" />
      </Helmet>
      <FeaturePage
        title="The only bank you’d need.
        Make fast local and interntional bank tranfers at the best rates."
        features={features}
        img={img}
        actionLine="Explore Changera Now"
        catchPhrase="MONEY TRANSFER AND PAYMENTS"
      />
    </div>
  );
};

export default Transfers;
