import FeaturePage from "views/components/feature-page";
import img from "app/assets/png/Wallets.png";
import { Helmet } from "react-helmet";

const features = [
  "Claim over 5 free multi-currency wallets including dollars, pound, euro, cedis, kenyan shillings and naira.",
  "Easily switch local currency across different currency wallets.",
  "Hold hard currency. Beat devaluation."
];

const Wallets = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="The money converter without stressful exchange rates and fees. Get Changera’s Multi-currency conversion wallets to transact in different currencies."
        ></meta>
        <title>
          Multi-currency Wallets – Money conversion faster with Changera
        </title>
        <link
          rel="canonical"
          href="https://changera.co/multi-currency-wallets"
        />
      </Helmet>
      <FeaturePage
        title="Never call up an Aboki “currency agent” again.
        Easily convert across currencies with our wallets."
        features={features}
        img={img}
        actionLine="Get a Changera Wallet Now"
        catchPhrase="MULTI-CURRENCY WALLETS"
      />
    </div>
  );
};

export default Wallets;
