import { Box, Image } from "@chakra-ui/react";
import FirstStep from "app/assets/png/firstStep.png";
import SecondStep from "app/assets/png/secondStep.png";
import ThirdStep from "app/assets/png/thirdStep.png";
const steps = [
  { title: "Sign up and verify your account", no: "1", image: FirstStep },
  { title: "Create and fund your wallet", no: "2", image: SecondStep },
  { title: "Subscribe and upgrade account", no: "3", image: ThirdStep }
];

export const GetStarted = () => {
  return (
    <div className="bg-[#126730] rounded-3xl md:rounded-[40px] max-w-[1600px] mx-auto">
      <Box px={["6", "6", "6", "6rem"]} py={["10", "10", "16", "20"]}>
        <h3 className="text-center font-extrabold text-5xl md:text-6xl text-[#F9F9FA] max-w-md mx-auto font-bricolage">
          Get <span className="text-[#FFDD58]">started </span> on Changera in 3
          steps
        </h3>

        <div className="mt-8 md:mt-14 lg:mt-20 grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-12 lg:gap-16">
          {steps.map((step, idx) => (
            <div
              key={idx}
              className="flex flex-col items-center justify-center gap-4 text-[#F9F9FA]"
            >
              <div className="flex justify-center relative">
                <p className="text-4xl font-light absolute top-0 left-2 font-bricolage">
                  {step.no}.
                </p>
                <Image src={step.image} alt="step" className="w-[70%]" />
              </div>
              <p className="text-center text-4xl font-extrabold font-bricolage">
                {step.title}
              </p>
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
};
