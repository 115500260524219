import "@fontsource/rubik";
import "@fontsource/karla";
import "@fontsource-variable/urbanist";
import "@fontsource-variable/bricolage-grotesque";
// import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "app/theme/chakraTheme";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import Loading from "views/components/loading";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "views/components/scroll-to-top";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GOOGLE_TAG_KEY}`
};

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </ChakraProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
