import { Box, Heading, Stack, Text } from "@chakra-ui/react";

export default function CookiePolicies() {
  return (
    <Box
      px={["6", "6", "6", "7.5rem"]}
      py={["16", "16", "16", "24"]}
      bg="orange.50"
    >
      <Heading textAlign={"center"} pb="10">
        Bitmama Cookie Policies
      </Heading>
      <Stack spacing={"10"} fontSize="xl" px={["4", "4", "16", "24", "44"]}>
        <Stack spacing={"5"}>
          <Heading as={"h4"} fontSize="xl">
            Introduction
          </Heading>
          <Text>
            Bitmama may use a file known as cookies to see how you use our site
            and products during your visit to our site and use them to recognize
            you and store information in-between visits.
          </Text>
          <Text>
            With cookies, we can give you a better browsing experience, improve
            the quality of our products, and keep our marketing costs down. They
            let us make it easier to log in, and they show us where you might be
            having trouble with our products. Without them, we’d have no way to
            remember anything about you, such as whether you’re logged in.
          </Text>
          <Text>
            In this policy, we’ve explained how and why we use cookies, and what
            each of them does. We’ve broken them down into four groups:
          </Text>
        </Stack>
        <Stack spacing={"6"}>
          <Heading as={"h4"} fontSize="2xl">
            Types of Cookies
          </Heading>
          <Text>
            <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
              Account:
            </Text>{" "}
            means a Changera account a product by Bitmama Inc
          </Text>
          <Text>
            <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
              Our cookies:
            </Text>{" "}
            We need these cookies to run our website efficiently— they let us
            save your cookie preferences, and keep the site secure. Some of
            these may be manually disabled in your browser but may affect the
            functionality of the Site.
          </Text>
          <Text>
            <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
              Personalization cookies to give you a better browsing experience:
            </Text>{" "}
            This allows us to recognize repeat visitors to the Site and also
            record your browsing history, the pages you have visited, and your
            settings and preferences each time you visit the Site.
          </Text>
          <Text>
            This enables us to tailor the site to your needs, like displaying it
            in the same language as your web browser.
          </Text>
          <Text>
            <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
              Site Management Cookies:
            </Text>{" "}
            Site management cookies are used to maintain your identity or
            session on the Site so that you are not logged off unexpectedly, and
            any information you enter is retained from page to page. These
            cookies cannot be turned off individually, but you can disable all
            cookies in your browser.
          </Text>
          <Text>
            <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
              Analytics Cookies that help us improve our products:
            </Text>{" "}
            This cookie allows us to see how you reached use our sites and show
            us how you interact with our products. They help us find and fix
            problems for our customers, and they’re also known as analytical or
            performance cookies.{" "}
          </Text>
          <Text>
            These cookies also let us know what features on the Site are working
            the best and what features on the Site can be improved.
          </Text>
          <Text>
            <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
              Advertising Cookies that help us with our marketing:
            </Text>{" "}
            These improve our marketing and placing of advertising by third
            parties. These cookies help place advertisements that are most
            likely to be of interest to you.{" "}
          </Text>
          <Text>
            <Text as={"span"} fontSize="xl" fontWeight={"bold"}>
              Security Cookies:
            </Text>{" "}
            Security cookies help identify and prevent security risks. We use
            these cookies to authenticate users and protect user data from
            unauthorized parties.
          </Text>
        </Stack>
        <Stack>
          <Heading as={"h4"} fontSize="xl">
            How to Control and Block Cookies?
          </Heading>
          <Text>
            Most browsers are set to accept cookies by default. However, you can
            remove or reject cookies in your browser’s settings. Please be aware
            that such action could affect the availability and functionality of
            the Site.
          </Text>
        </Stack>
        <Stack>
          <Heading as={"h4"} fontSize="xl">
            Privacy Policy
          </Heading>
          <Text>
            For more information about how we use information collected by
            cookies and other tracking technologies, please refer to our Privacy
            Policy. This Cookie Policy is part of and is incorporated into our
            Privacy Policy. By using the Site, you agree to be bound by this
            Cookie Policy and our Privacy Policy.
          </Text>
        </Stack>
        <Stack spacing={"5"}>
          <Heading as={"h4"} fontSize="xl">
            Contact Us
          </Heading>
          <Text>
            All comments, questions, access requests, complaints, and other
            requests regarding the privacy policy should be sent to
            <a href="support@bitmama.io" className="text-green-500">
              {" "}
              support@bitmama.io
            </a>
          </Text>
          <Text>
            We may request additional details from you regarding your complaints
            and keep records of your requests and resolution.
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}
