import { Box, Grid, GridItem, Image } from "@chakra-ui/react";
import paymentChallenge from "app/assets/png/paymentChallenge.png";
import currencyConversion from "app/assets/png/currencyConversion.png";
export const GridFeatures = () => {
  return (
    <Box
      px={["6", "6", "6", "8rem"]}
      py={["10", "10", "16", "24"]}
      className="bg-white"
      maxW={"1600px"}
      mx="auto"
    >
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={[4, 6, 8]}
        color="#F9F9FA"
      >
        <GridItem
          rowSpan={[3, 3, 3, 2]}
          colSpan={[5, 5, 5, 2]}
          bg="#DF9949"
          p={6}
          borderRadius="40px"
          display={"flex"}
          flexDir="column"
          justifyContent={"center"}
        >
          <p className="font-bold text-3xl md:text-[40px] font-bricolage">
            Overcome Payment Challenges
          </p>
          <p className="text-lg md:text-xl mt-4 md:mt-6">
            Simplify your payments and enjoy seamless transactions, fair rates,
            and swift processing, whether you're managing cross-border payments
            or earning multiple currencies.
          </p>
          <div className="hidden lg:block mt-4 md:mt-6 w-full bg-white bg-opacity-[0.12] p-5 rounded-[20px]">
            <Image src={paymentChallenge} alt="step" />
          </div>
        </GridItem>
        <GridItem
          rowSpan={[3, 3, 3, 1]}
          colSpan={[5, 5, 5, 3]}
          bg="#126730"
          p={6}
          borderRadius="40px"
          display={"flex"}
          flexDir="column"
          justifyContent={"center"}
        >
          {" "}
          <p className="font-bold text-3xl md:text-[40px] font-bricolage">
            Access Stability & Flexibility
          </p>
          <p className="text-lg md:text-xl mt-4 md:mt-6">
            Load your card with stable coins like USDT, USDC, PYUSD, and more
            for global spending power without volatility. Enjoy seamless
            conversion, enhanced spending options, and the security of stable
            assets like the US dollar.
          </p>
        </GridItem>
        <GridItem
          rowSpan={[3, 3, 3, 1]}
          colSpan={[5, 5, 5, 3]}
          bg="#536ECD"
          p={6}
          borderRadius="40px"
          display={"flex"}
          flexDir="column"
          justifyContent={"center"}
        >
          {" "}
          <p className="font-bold text-3xl md:text-[40px] font-bricolage">
            Convert USDC to Physical Dollars with Ease
          </p>
          <p className="text-lg md:text-xl mt-4 md:mt-6">
            Instantly Cash Out Your USDC to USD via MoneyGram for Immediate
            Access to Tangible Funds, Bridging the Gap Between Digital and
            Physical Currency.
          </p>
        </GridItem>
        <GridItem
          rowSpan={3}
          colSpan={5}
          bg="#FFDD58"
          p={6}
          borderRadius="40px"
          display={"flex"}
          flexDir="column"
          justifyContent={"center"}
        >
          <div className="flex gap-6 lg:gap-8 items-center justify-between">
            <div className="lg:w-7/12">
              <p className="font-bold text-[#212327] text-3xl md:text-[40px] font-bricolage">
                Unlock Seamless Currency Conversion
              </p>
              <p className="text-lg md:text-xl mt-4 md:mt-6 text-[#444B59]">
                Easily convert currencies like USD, EUR, GBP, and more with
                competitive rates and no hidden fees. Enjoy global flexibility,
                instant conversion, and the convenience of managing your
                finances across multiple currencies from anywhere, anytime.
              </p>
            </div>
            <div className="hidden lg:block mt-4 md:mt-6 lg:w-4/12">
              <Image src={currencyConversion} alt="step" />
            </div>
          </div>
        </GridItem>
      </Grid>
    </Box>
  );
};
