import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";
import PricingCard from "views/components/pricing-card";
import bg1 from "app/assets/svg/bg-1.svg";
import bg2 from "app/assets/svg/bg-2.svg";
import bg3 from "app/assets/svg/bg-3.svg";
import FAQ from "views/components/faq";
import { Helmet } from "react-helmet";

const pricingList = [
  {
    title: "Freemium",
    price: "Free",
    catchPhrase: "Best for all Users",
    features: [
      "Access a max of 3 multicurrency wallets",
      "Perform currency conversions and make payments  with the 3 currencies to over 20 countries worldwide",
      "Access to our virtual cards with spending limits of up to $5,000 per month",
      "Gain instant access to 3 crypto wallets on Changera. Receive crypto deposit and convert to fiat",
      "Purchase or gift airtime and data across the world",
      "Book flights to anywhere in the world",
      "Voucher and Bill payments across the world",
      "Transfer money within 24 hours – 36 hours (Subject to destination)"
    ],
    colors: {
      chakra: "gray.100",
      hex: "#fff"
    }
  },
  {
    title: "Freelancer",
    price: "$50",
    catchPhrase: "Best for freelancers",
    features: [
      "Access to GBP and EUR virtual accounts  for non-residents or VISA holders to receive foreign deposits",
      "Make ACH/ SEPA / SWIFT and CRYPTO transfers",
      "Access up-to 7 multicurrency wallets",
      "Perform currency conversions and make payments  with  the 7 currencies to over 70 countries worldwide",
      "Access to our virtual cards with spending limits of up to $50,000 per month",
      "Gain instant access to 3 crypto wallets on Changera. Receive crypto and convert to fiat",
      "Purchase or gift airtime and data across the world",
      "Book flights to anywhere in the world",
      "Voucher and Bill payments across the world",
      "Medium transfer speed (1 – 3 hours) subject to the destination"
    ],
    colors: {
      chakra: "success.600",
      hex: "#1B9B48"
    }
  },
  {
    title: "Premium",
    price: "$100",
    catchPhrase: "Best for the hard players",
    features: [
      "Access to GBP and EUR virtual accounts  for non-residents or VISA holders to receive foreign deposits",
      "Make ACH/ SEPA / SWIFT and CRYPTO transfers",
      "Access over 15 multicurrency wallets",
      "Perform currency conversions and make payments  with  the 15+ currencies, to over 150 countries worldwide",
      "Access to our virtual cards with spending limits of up to $100,000 per month",
      "Gain instant access to 3 crypto wallets on Changera. Receive crypto deposit and convert to fiat",
      "Purchase or gift airtime and data across the world",
      "Book flights to anywhere in the world",
      "Voucher and Bill payments across the world",
      "Apple Pay cards",
      "Priority access to lounges at airports across the world",
      "Priority transfer speed (0-30 mins) subject to the destination"
    ],
    colors: {
      chakra: "gray.100",
      hex: "#fff"
    }
  }
];

const faqs = [
  {
    question: "What is Changera?",
    answer: "The fastest way to send and receive money globally."
  },
  {
    question: "How to create a changera wallet",
    answer:
      "After your account has been verified, you can easily create a maximum of 3 wallets as a freemium user. Simply go to your wallets and add the wallet currency of the wallet you want to create.  "
  },
  {
    question: "Can I deposit Crypto to Changera?",
    answer: "Yes, you can deposit crypto to your Changera crypto wallet"
  },
  {
    question: "What are the Crypto coins supported on Changera?",
    answer:
      "The coins supported on Changera are USDC, cUSD, cEUR and USDT (TRC-20 and ERC-20 networks)"
  },
  {
    question: "What can I do with the crypto deposited on Changera?",
    answer:
      "You can use it to fund your Changera virtual card. You can also convert the coin to your local currency and withdraw it to your bank account."
  },
  {
    question: "Can I withdraw Naira to GHS with Changera?",
    answer:
      "Yes, you can use Changera to send money to Ghana from Nigeria and vice versa."
  },
  {
    question: "What is Fiat on Changera?",
    answer:
      "Fiat is the same as local currency. FIAT wallets supported on Changera include Euro (EUR), Ghanaian cedis (GHS), Kenyan Shillings (KES), Nigerian Naira (NGN), Pounds (GBP), U.S Dollars (USD)."
  },
  {
    question: "What is the cost of creating wallets on Changera?",
    answer: "Creating wallets is free on Changera."
  },
  {
    question: "Can I withdraw from my Changera Card?",
    answer: "Yes, you can withdraw from your Changera card "
  },
  {
    question: "How to make wallet-to-wallet transfers?",
    answer:
      "To make wallet-to-wallet transactions, go to the Send Money feature on the Changera app, select the wallet you want to transfer from and select the transaction type - either via Bank transfer or Wallet-to-Wallet"
  },
  {
    question: "What to do after exceeding the three wallet creation limits?",
    answer:
      "To create more wallets, you can subscribe to either the Freelancer plan or Premium plan."
  },
  {
    question: "What are the daily/monthly limits on the virtual cards?",
    answer:
      "There is no daily card limit on virtual cards. However, the maximum amount your card can hold at any given time is $2500"
  },
  {
    question: "Can I send cryptocurrency to external wallets from Changera?",
    answer:
      "You cannot send cryptocurrency from Changera to any wallet. You can only convert your crypto coins to FIAT."
  },
  {
    question: "What username do I use for my wallet-to-wallet transfers?",
    answer:
      "You use the same username you used to register. You can find your username in the profile section of your mobile app. "
  },
  {
    question: "How can I get a virtual bank account? ",
    answer:
      "You can request for a EUR and GBP virtual account when you upgrade your subscription to the Freelancer or Premium plan. "
  },
  {
    question: "Can I send money from my FIAT wallet to my virtual account?",
    answer:
      "Your virtual account is tied to your FIAT wallet which means that funding transactions on your virtual account is automatically reflected on your wallet balance. "
  },
  {
    question: "Where is Changera located?",
    answer: "Changera is registered in Philadelphia Pike, USA."
  }
];

const Pricing = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Get started on Changera with our Feemium, Freelancer and premium packeges."
        ></meta>
        <title>Get started with Changera</title>
        <link rel="canonical" href="https://changera.co/cards" />
      </Helmet>
      <Stack
        px={["6", "6", "6", "7.5rem"]}
        py={["16", "16", "16", "24"]}
        color={"primary.500"}
        background="white"
        bgImage={[
          `url(${bg1}), url(${bg2})`,
          `url(${bg1}), url(${bg2})`,
          `url(${bg1}), url(${bg2}), url(${bg3})`,
          `url(${bg1}), url(${bg2}), url(${bg3})`
        ]}
        bgPosition={[
          "10rem -5%, -15rem 30%",
          "15rem -5%, -10rem 30%",
          "calc(100% + 2.5rem) -5%, -10rem 30%, calc(100% + 15rem) 90%",
          "calc(100% + 3.5rem) -10rem, -10rem 50%, calc(100% + 15rem) 100%"
        ]}
        bgSize={[
          "400px 400px, 400px 400px",
          "400px 400px, 400px 400px",
          "400px 400px, 400px 400px, 600px 600px",
          "600px 600px, 600px 600px, 600px 600px"
        ]}
        bgRepeat="no-repeat"
      >
        <Heading
          fontWeight={"black"}
          lineHeight="1.2"
          fontFamily={"'Bricolage Grotesque Variable'"}
          fontSize={["3xl", "4xl"]}
          pb={["2", "4", "6"]}
          textAlign={["left", "center", "center"]}
        >
          Ready to get Started?
        </Heading>
        <Text
          fontSize={"20px"}
          fontWeight="normal"
          py={"2"}
          textAlign={["left", "center", "center"]}
        >
          Choose the plan which works best for you.
        </Text>
        <Flex
          direction={"row"}
          gap="4"
          justifyContent="space-evenly"
          alignItems={"flex-start"}
          flexWrap={"wrap"}
        >
          {pricingList.map((el, idx) => (
            <PricingCard
              key={idx}
              title={el.title}
              price={el.price}
              catchPhrase={el.catchPhrase}
              features={el.features}
              colors={el.colors}
            />
          ))}
        </Flex>
        <div id="faqs">
          <Heading
            fontWeight={"black"}
            lineHeight="1.2"
            fontFamily={"'Bricolage Grotesque Variable'"}
            fontSize={["3xl", "4xl"]}
            py={["2", "4", "4"]}
            textAlign={["left", "center", "center"]}
          >
            Frequently Asked Questions
          </Heading>
          <Text
            fontSize={"20px"}
            fontWeight="normal"
            py={"2"}
            textAlign={["left", "center", "center"]}
          >
            Everything you need to know about the product and billing.
          </Text>
          <Box px={["0", "@", "8", "16", "36"]} py="10">
            <FAQ faqs={faqs} />
          </Box>
          <Stack bg="#F3F5FE" p={["6", "6", "10"]} rounded="lg">
            <Flex alignItems={"center"} justifyContent="center">
              <Avatar
                transform={"translateX(10px)"}
                name="Kent Dodds"
                src="https://bit.ly/kent-c-dodds"
                border={"2px"}
                borderColor="gray.200"
              />
              <Avatar
                size={"lg"}
                name="Kent Dodds"
                src="https://bit.ly/kent-c-dodds"
                border={"2px"}
                borderColor="gray.200"
              />
              <Avatar
                name="Kent Dodds"
                transform={"translateX(-10px)"}
                src="https://bit.ly/kent-c-dodds"
                border={"2px"}
                borderColor="gray.200"
              />
            </Flex>
            <Heading
              fontWeight={"black"}
              lineHeight="1.2"
              fontFamily={"'Bricolage Grotesque Variable'"}
              fontSize={"xl"}
              py={["2", "4", "4"]}
              textAlign="center"
            >
              Still have questions?
            </Heading>
            <Text py={"2"} textAlign="center">
              Can’t find the answer you’re looking for? Please chat with our
              friendly team.
            </Text>
            <Box as="a" href="mailto:support@changera.co" textAlign={"center"}>
              <Button
                colorScheme={"primary"}
                mt="8"
                w={["full", "fit-content"]}
                py="6"
                px="16"
              >
                Get in Touch
              </Button>
            </Box>
          </Stack>
        </div>
      </Stack>
    </div>
  );
};

export default Pricing;
