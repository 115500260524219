import { Icon } from "@chakra-ui/react";
import Star from "app/assets/svg/Star";
import Marquee from "react-fast-marquee";

export const ScrollDownload = () => {
  return (
    <div className="bg-white -mt-10 md:-mt-12 skewing">
      <div className="py-3 pb-20">
        <Marquee speed={20} direction="right" pauseOnHover>
          {[...Array(20)].map((_, idx) => (
            <div className="flex items-center gap-4 mx-2" key={idx}>
              <Icon as={Star} height={15} color={"#126730"} />
              <p className="font-semibold text-lg text-[#126730]">
                DOWNLOAD CHANGERA
              </p>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};
