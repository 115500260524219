import FeaturePage from "views/components/feature-page";
import img from "app/assets/png/social_money.png";
import { Helmet } from "react-helmet";

const features = [
  "Share money requests to friends and family home and abroad.",
  "Make free & instant local and foreign wallet transfers with just names, no bank accounts.",
  "Earn points and increase your ranking as you transact.",
  "Pay bills and gift airtime in 25+ countries globally.",
  "Find your favourite gift cards and vouchers."
];

const SocialMoney = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Money is social on Changera. Pay bills, buy airtime, send money and airtime to loved ones faster. Get Changera to start spending your money freely in one app"
        ></meta>
        <title>Pay bills, buy and gift airtime to anyone on Changera app</title>
        <link rel="canonical" href="https://changera.co/social-money" />
      </Helmet>
      <FeaturePage
        title="Money is social on Changera"
        features={features}
        img={img}
        actionLine="Explore Changera Now"
        catchPhrase="SOCIAL MONEY FEATURES"
      />
    </div>
  );
};

export default SocialMoney;
