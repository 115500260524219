import { motion } from "framer-motion";

import { Image } from "@chakra-ui/react";
import transfers from "app/assets/png/transfers.png";
import settlement from "app/assets/png/settlement.png";
import convert from "app/assets/png/convert.png";
import cards from "app/assets/png/cards.png";
import transfersPattern from "app/assets/svg/transfersPattern.svg";
import settlementPattern from "app/assets/svg/settlementPattern.svg";
import convertPattern from "app/assets/svg/convertPattern.svg";

const features = [
  {
    title: "Say Goodbye to Delayed Deposits",
    description:
      "Eliminate processing delays and excessive fees. With a simple tap, enjoy swift local and international transfers, guaranteed to be both rapid and successful.",
    image: transfers,
    backColor: "#DF9949",
    backPattern: transfersPattern
  },
  {
    title: "Faster Settlements Tailored for You",
    description:
      "Experience lightning-fast transfers domestically and internationally, leveraging robust systems like ACH, CRYPTO, SEPA, and SWIFT. Enjoy seamless transactions with features such as quick bank-to-bank transfers, borderless cryptocurrency transactions, and secure cross-border fund transfers.",
    image: settlement,
    backColor: "#6484F6",
    backPattern: settlementPattern
  },
  {
    title: "Dollars, Pounds & Euros in Your Wallet",
    description:
      "Bank globally from the comfort of home with Changera's virtual Euro (EUR), Dollar (USD) and British Pound (GBP) accounts. Enjoy low fees, currency diversification, top-notch security, and hassle-free online banking for a seamless financial experience.",
    image: convert,
    backColor: "#0A0E1F",
    backPattern: convertPattern
  },
  {
    title: "Unlock Global Possibilities",
    description:
      "Access Virtual Dollar Cards for seamless global transactions, accepted worldwide wherever Visa cards are honored. Enjoy generous spending limits of up to $20k monthly, instant card issuance, and secure transactions protected by advanced encryption technology.",
    image: cards,
    backColor: "#DF9949",
    backPattern: transfersPattern
  }
];

const Products = () => {
  return (
    <div className="py-16 lg:py-20 min-h-svh max-w-[1600px] mx-auto relative z-50 bg-white">
      <h3 className="text-center font-extrabold text-5xl md:text-6xl text-[#212327] pb-10 px-4 font-bricolage">
        <span className="text-[#126730]">1m+ </span>Downloads,{" "}
        <span className="text-[#126730]">5+</span> Countries
      </h3>

      <div className="sm:hidden">
        <MobileView />
      </div>

      <div className="hidden sm:block">
        <div className="px-6 md:px-12 xl:px-20 flex gap-6 overflow-x-auto space-x-8 mt-12 no-scrollbar flex-row">
          {features.map((product, idx) => {
            return <Card data={product} key={idx} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Products;

const Card = ({
  data
}: {
  data: {
    title: string;
    description: string;
    image: string;
    backColor: string;
    backPattern: string;
  };
}) => {
  return (
    <motion.div
      className="relative px-6 md:px-14 py-10 rounded-[40px] lg:min-h-[430px] flex items-center min-w-[700px] lg:min-w-[1200px]"
      style={{
        backgroundColor: data.backColor
      }}
    >
      <div className="flex-col lg:flex-row flex items-center gap-6 md:gap-10 justify-between text-[#F9F9FA] !z-[20] relative w-full">
        <div className="lg:w-7/12">
          <p className="font-bold text-4xl md:text-6xl font-bricolage">
            {data.title}
          </p>
          <div className="bg-white bg-opacity-[0.12] p-4 rounded-3xl mt-4 md:mt-6">
            <p className="text-lg md:text-xl">{data.description}</p>
          </div>
        </div>
        <motion.div className="hidden lg:flex lg:w-5/12 justify-center lg:justify-end">
          <Image src={data.image} alt="step" className="w-[90%]" />
        </motion.div>
      </div>
      <Image
        src={data.backPattern}
        height="full"
        display={["none", "none", "none", "none", "block"]}
        position={["absolute"]}
        opacity={"1"}
        margin="auto"
        right="0"
        top={0}
      />
    </motion.div>
  );
};

const MobileView = () => {
  return (
    <div className="flex flex-col my-4">
      {features.map((data, idx) => {
        return (
          <div
            className="px-6 py-10 text-[#F9F9FA] "
            key={idx}
            style={{
              backgroundColor: data.backColor
            }}
          >
            <p className="font-bold text-4xl md:text-6xl font-bricolage">
              {data.title}
            </p>
            <div className="bg-white bg-opacity-[0.12] p-4 rounded-3xl mt-4 md:mt-6">
              <p className="text-lg md:text-xl">{data.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
